import Axios from "axios";
import React, { useEffect, useState } from "react";
import ProductCard from "../components/Cards/ProductCard";

import "./Products.css";

export default function Products(props){

    const [products,setProducts] = useState([]);

    useEffect(()=>{
      
        const sendReq = async ()=>{
            const response = await Axios.get(process.env.REACT_APP_BACKEND_URL+"/api/products/get-products");
            setProducts(response.data);
          }
     
          sendReq();


    },[])

    return (
        <div className = "container">
            <div className = "website-product-card-container">
              <div className = "main-website-products-card-container">
                 {products.map((product,index)=>{
                   return( <ProductCard key = {index} image = {product.Link} title = {product.Name} detail = {product.Description}/>);
                 })}
                 {products.length === 0 &&
                 
                   <h1>NO PRODUCTS ARE AVAILABLE</h1>
                 
                 }
              </div>
            </div>
        </div>
    );
}