import Axios from "axios";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { tableIcons } from "../../TableIcons";

import "./Stocks.css";

export default function Stocks(props) {

  const [stock,setStock] = useState([]);
  
  useEffect(()=>{
   
  const sendReq = async () => {
     const response = await Axios.get(
         process.env.REACT_APP_BACKEND_URL + "/api/products/get-stock"
        );
        setStock(response.data);
      };
    
      sendReq();


  
  },[])  
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">Stock</h2>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Product Name", field: "Name" },
          { title: "Quantity", field: "Quantity" },
        ]}
        data={stock}
        title="Stock Details"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
