import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../../../TableIcons";

import "./ListOrders.css";
import Axios from "axios";

export default function ListOrders(props){
    
    const [orders,setOrders] = useState([]);

    useEffect(()=>{
     
      const sendReq = async ()=>{
          const response = await Axios.get(process.env.REACT_APP_BACKEND_URL+"/api/orders/get-orders");

          setOrders(response.data);
      }

      sendReq();    

    },[])

    return (
        <div className="page-container">
          <h2 className="dashboard-heading">ORDERS LIST</h2>
          <MaterialTable
            icons={tableIcons}
            columns={[
              { title: "Product Name", field: "ProductName" },
              { title: "Manufacturer Name", field: "ManufacturerName" },
              { title: "Number of Vials", field: "Number" },
              { title: "Total Amount", field: "Amount" },
              { title: "Date", field: "Date" }
            ]}
            data={orders}
            title="Order Details"
          />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      );
 

}