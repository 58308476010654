import React from "react";
import PaymentIcon from "@material-ui/icons/PaymentOutlined";
import ChatIcon from "@material-ui/icons/Chat";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";

import "./StatusCard.css";

export default function StatusCard(props) {
  return (
    <div className="admin-panel-status-card">
      <div
        className="status-card-icon-container"
        style={{ backgroundColor: props.color }}
      >
        {props.icon === "sales" && <PaymentIcon className="status-card-icon" />}
        {props.icon === "commission" && (
          <CartIcon className="status-card-icon" />
        )}
        {props.icon === "items" && <ChatIcon className="status-card-icon" />}
        {props.icon === "orders" && (
          <PeopleAltIcon className="status-card-icon" />
        )}
      </div>
      <div
        className="status-card-text-container"
        style={{ backgroundColor: props.color }}
      >
        <p className="status-card-main-content">{props.mainContent}</p>
        <p className="status-card-sub-content">{props.subContent}</p>
      </div>
    </div>
  );
}
