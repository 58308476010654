import React, {useEffect, useState } from "react";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import "./Carousel.css";
import { Link } from "react-router-dom";
 
const slidearray = [{
  heading1:"Synapes Life Sciences Pvt. Ltd.",
  heading2:"An ISO 9001:2015 Certified Company"
},
{
  heading1:"Sense and Simplicity",
  heading2:"Positive Results for Life"
},
{
  heading1:"We innovate healthcare",
  heading2:"Passion for innovation. Compassion for patients"
}
]


function AutoCarousel(){

  const [index,setIndex] = useState(0);

  function previousSlide(){
    setIndex(prev=>prev-1);
  }
  function forwardSlide(){
    setIndex(prev=>prev+1);
  }

  useEffect(()=>{
    const timer = setInterval(()=>{
      setIndex(index+1);
    },13000);
    
    //clearing setInterval

    return ()=>clearInterval(timer);

  },[index]);


  return (
    <div className = "carousel black-bg">
    <div className = "arrow-container-left">
       <ArrowBackIosIcon className = "forward-slide" onClick = {previousSlide} style = {{fontSize:"4rem",margin:"10px"}}/>
     </div>
     <div className = "arrow-container-right">
       <ArrowForwardIosIcon className = "previous-slide" onClick = {forwardSlide} style = {{fontSize:"4rem",margin:"10px"}}/>
     </div>  
        {index%3 === 0 &&    
        <div className = "carousel-item-1">
          <div className = "headings-container">
          <p className = "heading1">{slidearray[0].heading1}</p>
          <p className = "heading2">{slidearray[0].heading2}</p>
          <div className = "carousel-button-container">
             <Link className = "no-style-link" to = "/about"><button className = "learn-more-button">LEARN MORE</button></Link>
             <Link className = "no-style-link" to = "/contact"><button className = "contact-us-button">CONTACT US</button></Link>
          </div>
          </div>
        </div>
        }
        {index%3 === 1 &&    
        <div className = "carousel-item-2">
         <div className = "headings-container">
          <p className = "heading1">{slidearray[1].heading1}</p>
          <p className = "heading2">{slidearray[1].heading2}</p>
          <div className = "carousel-button-container">
             <Link className = "no-style-link" to = "/about"><button className = "learn-more-button">LEARN MORE</button></Link>
             <Link className = "no-style-link" to = "/contact"><button className = "contact-us-button">CONTACT US</button></Link>
          </div>
          </div>
        </div>
        }
        {index%3 === 2 &&    
        <div className = "carousel-item-3">
        <div className = "headings-container">
          <p className = "heading1">{slidearray[2].heading1}</p>
          <p className = "heading2">{slidearray[2].heading2}</p>
          <div className = "carousel-button-container">
             <Link className = "no-style-link" to = "/about"><button className = "learn-more-button">LEARN MORE</button></Link>
             <Link className = "no-style-link" to = "/contact"><button className = "contact-us-button">CONTACT US</button></Link>
          </div>
        </div>  
        </div>
        }
    </div>
   );

}


export default AutoCarousel;