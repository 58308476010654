import React, { useState } from 'react';

import "./MainNavigation.css";
import NavLink from "../Navigation/components/NavLink";
import logoImage from "../../Images/Synapes_logo_1.png";
import logoText from "../../Images/Synapes_logo_2.png";
import { Link, useHistory } from 'react-router-dom';
import MenuIcon from "@material-ui/icons/Menu";

export default function MainNavigation(props){
    
   const history = useHistory();

   function logoClickHandler(event){
      history.push("/");
   }

   const [mobileMenuActive,setMobileMenuActive] = useState(false);

   function mobileMenuClickHandler(){
      setMobileMenuActive(prev=>!prev);
   }
   
   return (
   <React.Fragment>
   <nav className = "main-navigation-bar">
       <div className = "nav-logo-container" onClick = {logoClickHandler}>
          <img className = "logo-image" src = {logoImage} alt = "logo"/>
          <img className = "logo-content" src = {logoText} alt = "logo"/>
       </div>
       <ul className = "navigation-links-container">
          <NavLink route = "/about">About us</NavLink>
          <NavLink route = "/products">Products</NavLink>
          <NavLink route = "/careers">Careers</NavLink>
          <NavLink route  = "/responsibility-and-health">Responsibility & Health</NavLink>
          <NavLink route = "/contact">Contact us</NavLink>
       </ul>
      <MenuIcon className = "mobile-menu-icon" onClick = {mobileMenuClickHandler}/>
    </nav>
    { mobileMenuActive && 
    <div className = "mobile-menu">
      <ul className = "mobile-navigation-links-container">
            <Link className = "mobile-menu-link" to = "/about" onClick = {mobileMenuClickHandler}>About us</Link>
            <Link className = "mobile-menu-link" to = "/products" onClick = {mobileMenuClickHandler}>Products</Link>
            <Link className = "mobile-menu-link" to = "/careers" onClick = {mobileMenuClickHandler}>Careers</Link>
            <Link className = "mobile-menu-link" to  = "/responsibility-and-health" onClick = {mobileMenuClickHandler}>Responsibility & Health</Link>
            <Link className = "mobile-menu-link" to = "/contact" onClick = {mobileMenuClickHandler}>Contact us</Link>
       </ul>
    </div>
    }
    </React.Fragment>   
   );
}