import React from "react";
import "./ProductCard.css";

export default function ProductCard(props){
    return(
        <div className = "website-product-card">
           <div className = "website-product-image-container">
               <img src = {`${process.env.REACT_APP_BACKEND_URL}/${props.image}`} alt = "product"/>
           </div>   
           <div className = "website-product-details-container">
               <p>{props.title}</p>
               <p>{props.detail}</p>
           </div>
        </div>
    );
}