import React from "react";

import "./Careers.css";
import whyImage from "../Images/whyUS.jpg";
import InfoWithImage from "../components/Info Section/InfoWithImage";
import EmployeeBenefits from "../Images/employeeBenefits.jpg";
import CareersImage from "../Images/careers.jpg";
import { MetaTags } from "react-meta-tags";

export default function Careers(props) {
  return (
    <div className="container">
      <MetaTags>
        <meta
          name="keywords"
          content="Human Resource,WHY Synapes, team members’, growth and development, performance-driven, growth and succes, global leader in healthcare"
        />
        <meta
          name="description"
          content="Working with synapes life science is a dream come true, beacuse we not only nurture the great minds who join us,but also answer them why synapes. growth and development is our moto."
        />
      </MetaTags>

      <InfoWithImage
        heading="Why Choose Us ?"
        image={whyImage}
        content="Whether it’s helping invent the next breakthrough treatment or simply challenging and supporting one another for mutual betterment, our culture is about applied curiosity. We are dedicated to our team members’ growth and development, and empower each of them to reach their full potential regardless of function, geography or experience level."
        left="true"
      >
        <p className="sub-heading">We are :</p>
        <p className="sub-points">
          1. Committed to fostering development and rewarding talent
        </p>
        <p className="sub-points">
          2. Dedicated to diversity and inclusion at every level of our
          organization
        </p>
        <p className="sub-points">
          3. Adept at recognizing unique skill sets and nurturing our employees’
          talents
        </p>
      </InfoWithImage>

      <InfoWithImage
        heading="Talented teams, performance-based compensation"
        image={EmployeeBenefits}
        content="The success of Synapes depends on the performance and dedication of our Group company associates. We strive to be an employer of choice that attracts, retains and motivates talented and performance-driven people in our affiliates around the world. Each associate is given a fixed salary based on job characteristics, market competitiveness and the associate’s skills. Salary growth depends on the associate's individual performance and level compared to the benchmark. Synapes benefits programs are an integral part of the total compensation policy and strategy and are designed to meet the challenges of the growing, global competition for talent. These programs support the overall business objectives and strategy of Synapes. Benefits are aligned with local legislation and practices in each country and are established to provide a framework of security for associates."
        right="true"
      />

      <InfoWithImage
        heading="Working at Synapes"
        image={CareersImage}
        content="Our talented, diverse and driven teams work collaboratively to bring our pipeline of innovative medicines to life. Synapes has a vision of a better today and tomorrow for patients – a vision that drives our growth and success. The greatest job satisfaction for our associates is the knowledge that they improve the quality of life for patients through breakthrough science and innovation."
        left="true"
      >
        <p className="sub-heading">Search Careers</p>
        <p className="sub-points">
          Our performance-oriented culture and responsible business approach
          attract top experts in many areas, marketing and sales, finance and
          administration. Our talented associates have made us a global leader
          in healthcare. Synapes is committed to rewarding the people who invest
          their ideas and their time in our company.
        </p>
      </InfoWithImage>
    </div>
  );
}
