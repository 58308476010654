import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Favicon from "react-favicon";
import logo from "../src/Images/favicon.ico";

import "./App.css";
import Routes from "./Routes/Routes";
import { useAuth } from "./hooks/auth-hook";
import { AuthContext } from './context/AuthContext';


export default function App(){

    const {userId,userEmail,userName,userRole,login,logout} = useAuth();
    
    return (
        <AuthContext.Provider value = {{isLoggedIn: !!userId,userEmail:userEmail,userId:userId,userName:userName,userRole:userRole, login:login,logout:logout}}>
        <Favicon url = {logo}/>
        <Router>
           <ScrollToTop />
           <Routes />
        </Router>
        </AuthContext.Provider>
        
    )
}