import React from "react";

import "./InputLabelForForm.css";

export default function InputLabelForForm(props) {
  return (
    <label className={`input-form-label ${props.className}`}>
      {props.children}<br/>
    </label>
  );
}
