import React from "react";

import "./ProgressBar.css";

export default function ProgressBar(props) {
  return (
    <div className="progress-outer-bar">
      <div
        className="progress-inner-bar"
        style={{ backgroundColor: props.color, width: props.percentage }}
      ></div>
    </div>
  );
}
