import React, { useState } from "react";
import ImageInput from "../../../../Form/components/ImageInput";

import Input from "../../../../Form/components/Input";
import "./AddProduct.css";
import Textarea from "../../../../Form/components/Textarea";
import PanelButton from "../../../Buttons/PanelButton";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";

export default function AddProduct(props) {

  const [addProductForm, setAddProductForm] = useState({
    Name: "",
    Description: "",
    image: null,
  });

  const history = useHistory();

  function formSubmitHandler(event){

    event.preventDefault();

    const data = new FormData();

    data.append('Name',addProductForm.Name);
    data.append('Description',addProductForm.Description);
    data.append('image',addProductForm.image);
   
    
    const sendReq = async ()=>{
       const response = await Axios.post(process.env.REACT_APP_BACKEND_URL+"/api/products/add-product",
       data
       );

       alert(response.data.message);
       history.push("/admin-panel/products/list-products")
       
    }

    sendReq();


  }


  function inputChangeHandler(event) {
    let name = event.target.name;

    if (name === "product-name") {
      setAddProductForm((prev) => {
        return { ...prev, Name: event.target.value };
      });
    } else if (name === "image") {
      setAddProductForm((prev) => {
        return { ...prev, image:event.target.files[0]};
      });
    } else if (name === "description") {
      setAddProductForm((prev) => {
        return { ...prev, Description: event.target.value };
      });
    }
  }

  
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">ADD PRODUCT</h2>
      <form className="add-product-form" onSubmit = {formSubmitHandler}>
        <div className="add-product-image-form">
          <ImageInput
            className="add-product-image-input"
            name="image"
            onChange={inputChangeHandler}
          />
        </div>
        <div className="add-product-details-form">
          <Input
            name="product-name"
            label="Product Name "
            value={addProductForm.Name}
            onChange={inputChangeHandler}
          />
          <Textarea
            rows={10}
            name="description"
            label="Add Description "
            value={addProductForm.Description}
            onChange={inputChangeHandler}
          />
          <Link to = "/admin-panel"><PanelButton className="discard-product-btn">DISCARD</PanelButton></Link>
          <PanelButton className="add-product-btn">ADD</PanelButton>
        </div>
      </form>
    </div>
  );
}
