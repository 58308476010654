import React from "react";

import "./Table.css";

export default function Table(props){
    return (
      <div className = {`responsive-table ${props.className}`}>
        <table className = {`admin-panel-table`}>
          <tbody>
           {props.children}
          </tbody>
        </table>
        </div>
    );

}