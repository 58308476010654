import React from "react";

import "./Select.css";

export default function Select(props) {
  return (
    <div className="form-input-container">
      <span className="form-input-label">{props.label}</span>
      <select
        name={props.name}
        value={props.value}
        type={props.type}
        className={`admin-panel-form-select ${props.className}`}
        onChange={props.onChange}
      >
        {
          <option disabled selected>
            {props.placeholder}
          </option>
        }
        {props.options.map((optionName) => {
          return <option value={optionName}>{optionName}</option>;
        })}
      </select>
    </div>
  );
}
