import MaterialTable from "material-table";
import React from "react";
import { tableIcons } from "../../../TableIcons";

import "./PageList.css";

export default function PageList(props) {
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">PAGE LIST</h2>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Id", field: "id" },
          { title: "Name", field: "name" },
          { title: "Status", field: "status" },
          { title: "Created On", field: "created_on" },
        ]}
        data={[
          {
            id: "#P02",
            name: "User Media",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#P02",
            name: "User Media",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#P02",
            name: "User Media",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#P02",
            name: "User Media",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#P02",
            name: "User Media",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#P02",
            name: "User Media",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#P02",
            name: "User Media",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#P02",
            name: "User Media",
            status: "active",
            created_on: "Sep 5, 2020",
          },
        ]}
        actions={[
          {
            icon: "delete",
            iconProps: { style: { color: "#E4566E" } },
            tooltip: "Delete",
            onClick: (event, rowData) => {
              // Do save operation
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Pages List"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
