import React from "react";

import "./Input.css";

export default function Input(props) {
  return (
    <div className="form-input-container">
      <span className="form-input-label">{props.label}</span>
      <input
        name={props.name || ""}
        type={props.type || "text"}
        placeholder={props.placeholder || ""}
        className={`admin-panel-form-input ${props.className}`}
        value={props.value}
        onChange={props.onChange}
      />
    </div>
  );
}
