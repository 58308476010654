import Axios from "axios";
import React, { useEffect, useState } from "react";

import Input from "../../../Form/components/Input";
import InputLabelForForm from "../../../Form/components/InputLabelForForm";
import PanelButton from "../../Buttons/PanelButton";

import "./Bill.css";

export default function Bill(props) {

  const [price,setPrice] = useState();
  
 
  const [formData,setFormData] = useState({
     StoreName:"Select Store/Distributor",
     ProductName:"Select Product",
     Number:"",
     TotalAmount:""
  })

  const formChangeHandler = (event)=>{
    let name = event.target.name;

    let value = event.target.value;

    if(name === "product"){
      setFormData((prev)=>{
        return {...prev,ProductName:value};
      })
    }else if(name === "store"){
      setFormData((prev)=>{
        return {...prev,StoreName:value};
      })
    }else if(name === "number"){
      setFormData((prev)=>{
        return {...prev,Number:value};
      })
    }else if(name === "price"){
      setPrice(value);
      setFormData((prev)=>{
        return {...prev,TotalAmount:value*formData.Number};
      })
    }
  }


  function formSubmitHandler(e){
    e.preventDefault();
    const sendReq = async ()=>{
      const response = await Axios.post(process.env.REACT_APP_BACKEND_URL+"/api/distributors/add-bill",formData);
      alert(response.data);
    }

    sendReq();
  }


  const [distributors,setDistributors] = useState([]);
  const [products, setProducts] = useState([]);

  
  useEffect(()=>{
   
  const sendReq = async () => {
     const response = await Axios.get(
         process.env.REACT_APP_BACKEND_URL + "/api/products/get-products"
        );
        setProducts(response.data);
      };
    
      sendReq();

   const getDistributors = async ()=>{
       const response = await Axios.get(process.env.REACT_APP_BACKEND_URL+"/api/distributors/get-distributors");

       setDistributors(response.data);
   }

   getDistributors();

  },[])




  return (
    <div className="page-container">
      <h2 className="dashboard-heading">CREATE DISTRIBUTOR</h2>

      <div className="multi-page-form">
        <div className="multipage-controller">
        </div>
        <div className="multi-page-main-form-container">
            <form onSubmit = {formSubmitHandler}>
            <div className="form-input-container">
                  <span className="form-input-label">Choose Product</span>
                  <select
                    name = "product"
                    value = {formData.ProductName}
                    className={`admin-panel-form-select`}
                    onChange={formChangeHandler}
                  >
                      <option disabled defaultValue>
                        Select Product
                      </option>
                    {products.map((optionName,index) => {
                      return <option key = {index} value = {optionName.Name}>{optionName.Name}</option>;
                    })}
                  </select>
                </div>
                <div className="form-input-container">
                  <span className="form-input-label">Choose Distributor</span>
                  <select
                    name = "store"
                    value = {formData.StoreName}
                    className={`admin-panel-form-select`}
                    onChange={formChangeHandler}
                  >
                      <option disabled defaultValue>
                        Select Store/Distributor
                      </option>
                    {distributors.map((optionName,index) => {
                      return <option key = {index} value = {optionName.StoreName}>{optionName.StoreName}</option>;
                    })}
                  </select>
                </div>
            
              <Input label="Number of vials" name = "number" value = {formData.Number} onChange = {formChangeHandler} type = "number"/>
              <Input label="Price per vial " name = "price" value = {price} onChange = {formChangeHandler}/>
              <InputLabelForForm>Total, ₹ {formData.TotalAmount}</InputLabelForForm>
              <PanelButton className="add-page-btn">SAVE</PanelButton>
            </form>
        </div>
      </div>
    </div>
  );
}
