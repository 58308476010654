import MaterialTable from "material-table";
import React from "react";
import { tableIcons } from "../../../TableIcons";

import "./UserList.css";

export default function UserList(props) {
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">EMPLOYEES LIST</h2>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Id", field: "id" },
          {
            field: "avatar",
            title: "Avatar",
            render: (rowData) => (
              <img
                src={rowData.avatar}
                style={{ width: 50, borderRadius: "50%" }}
                alt="user"
              />
            ),
          },
          { title: "First Name", field: "f_name" },
          { title: "Last Name", field: "l_name" },
          { title: "Email", field: "email" },
          { title: "Last login", field: "last_login" },
          { title: "Role", field: "role" },
        ]}
        data={[
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
          {
            id: "#U901",
            avatar:
              "https://react.pixelstrap.com/multikart-admin/static/media/designer.c8814903.jpg",
            f_name: "Anup",
            l_name: "Kumar",
            email: "anupkumar@gmail.com",
            last_login: "2/10/20",
            role: "Customer",
          },
        ]}
        actions={[
          {
            icon: "delete",
            iconProps: { style: { color: "#E4566E" } },
            tooltip: "Delete",
            onClick: (event, rowData) => {
              // Do save operation
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Employees Details"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
