import React from "react";

import "./DropZone.css";

function preventFunction(event) {
  event.target.style.backgroundColor = "lightgray";
  event.preventDefault();
}

export default function Dropzone(props) {
  return (
    <div
      className="dropzone"
      onDrop={props.onDrop}
      onDragOver={preventFunction}
      onDragEnter={preventFunction}
    >
      <p>Drag Files or Click to Browse</p>
      <input type="file" className="choose-file-input" />
    </div>
  );
}
