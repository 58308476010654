import React, { useEffect, useState } from "react";
import PanelButton from "../../../Buttons/PanelButton";
import Input from "../../../../Form/components/Input";

import "./PlaceOrder.css";
import Axios from "axios";
import { useHistory } from "react-router-dom";

export default function PlaceOrder(props){

  const [manufacturers,setManufacturers] = useState([]);
  const [products, setProducts] = useState([]);

  
  useEffect(()=>{
   
  const sendReq = async () => {
     const response = await Axios.get(
         process.env.REACT_APP_BACKEND_URL + "/api/products/get-products"
        );
        setProducts(response.data);
      };
    
      sendReq();


   const getManufacturers = async ()=>{
       const response = await Axios.get(process.env.REACT_APP_BACKEND_URL+"/api/manufacturer/get-manufacturer");

       setManufacturers(response.data);
   }

   getManufacturers();

  },[])


    const [formData,setFormData] = useState({
        Number:"",
        Amount:"",
        ProductName:"Select Product",
        ManufacturerName:"Select Manufacturer",
        Date:""
    });

    const history = useHistory();

    const formChangeHandler = (e)=>{
        if(e.target.name === "number"){
          setFormData((prev)=>{
            return {...prev,Number:e.target.value};
          })
        }else if(e.target.name === "amount"){
          setFormData((prev)=>{
            return {...prev,Amount:e.target.value};
          })
        }else if(e.target.name === "product"){
          setFormData((prev)=>{
            return {...prev,ProductName:e.target.value};
          })
        }else if(e.target.name === "manufacturer"){
          setFormData((prev)=>{
            return {...prev,ManufacturerName:e.target.value};
          })
        }else if(e.target.name === "date"){
          setFormData((prev)=>{
            return {...prev,Date:e.target.value};
          })
        }
    }

    function submitForm(){
      const sendReq = async ()=>{
        const response = await Axios.post(process.env.REACT_APP_BACKEND_URL+"/api/orders/add-order",formData);

        alert(response.data);

        history.push("/admin-panel/orders/list-orders");
      }

      sendReq();
    }

    return (

        <div className="page-container">
        <h2 className="dashboard-heading">Place Order</h2>
  
        <div className="multi-page-form">
          <div className="multipage-controller">
          </div>
          <div className="multi-page-main-form-container">
              <div>
              <div className="form-input-container">
                  <span className="form-input-label">Choose Product</span>
                  <select
                    name = "product"
                    value = {formData.ProductName}
                    className={`admin-panel-form-select`}
                    onChange={formChangeHandler}
                  >
                      <option disabled defaultValue>
                        Select Product
                      </option>
                    {products.map((optionName,index) => {
                      return <option key = {index} value = {optionName.Name}>{optionName.Name}</option>;
                    })}
                  </select>
                </div>

                <div className="form-input-container">
                  <span className="form-input-label">Choose Manufacturer</span>
                  <select
                    name = "manufacturer"
                    value = {formData.ManufacturerName}
                    className={`admin-panel-form-select`}
                    onChange={formChangeHandler}
                  >
                      <option disabled defaultValue>
                        Select Manufacturer
                      </option>
                    {manufacturers.map((optionName,index) => {
                      return <option key = {index} value = {optionName.Name}>{optionName.Name}</option>;
                    })}
                  </select>
                </div>
                
                <Input label="Number of Vials" name = "number" type = "number" value = {formData.Number} onChange = {formChangeHandler}/>
                <Input label="Total Amount, ₹" type="number" name = "amount" value = {formData.Amount} onChange = {formChangeHandler}/>
                <Input label="Date of Order" type="date" name = "date" value = {formData.Date} onChange = {formChangeHandler}/>
                <PanelButton className="add-page-btn" onClick = {submitForm}>SAVE</PanelButton>
              </div>
          </div>
        </div>
      </div>

    );
}