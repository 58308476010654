import React, { useState } from "react";
import ProgressBar from "../ProgressBar";
import ProfileImage from "../../../../Images/profileImage.jpg";
import PanelButton from "../../../Buttons/PanelButton";

import "./ProfilePage.css";

export default function ProfilePage(props) {
  const [activeFormPage, setActiveFormPage] = useState(0);

  function pageClickHandler(event) {
    const id = event.target.id;
    const activePage = parseInt(id.substring(id.length - 1));

    setActiveFormPage(activePage);
  }

  return (
    <div className="page-container">
      <div className="profiles-container">
        <div className="basic-profile-container">
          <div className="basic-details">
            <img alt="profile" src={ProfileImage} />
            <h4 style={{ margin: "10px" }}>Arun Sharma</h4>
            <p style={{ fontSize: "15px", color: "gray", margin: "0" }}>
              arunsharma3453@gmail.com
            </p>
          </div>
          <hr className="horizontal-rule" />
          <div className="employee-status-profile">
            <h4 style={{ margin: "10px 0" }}>Employee Status</h4>
            <br />
            <h5 className="progress-bar-heading">Performance 80%</h5>
            <ProgressBar color="#F9A2A3" percentage="80%" />
            <h5 className="progress-bar-heading">Overtime 60%</h5>
            <ProgressBar color="#3368C6" percentage="60%" />
            <h5 className="progress-bar-heading">Leaves taken 50%</h5>
            <ProgressBar color="#A5A5A5" percentage="50%" />
          </div>
        </div>
        <div className="detailed-profile-container">
          <div className="multi-page-form">
            <div className="multipage-controller">
              <ul className="multipage-controller-list">
                <li
                  className={
                    activeFormPage === 0 && "multipage-controller-list-active"
                  }
                  id="multi-page-form-0"
                  onClick={pageClickHandler}
                >
                  Profile
                </li>
                <li
                  className={
                    activeFormPage === 1 && "multipage-controller-list-active"
                  }
                  id="multi-page-form-1"
                  onClick={pageClickHandler}
                >
                  Contact
                </li>
              </ul>
            </div>
            <div className="multi-page-main-form-container">
              {activeFormPage === 0 && (
                <form>
                  <h3>Profile</h3>
                  <br />
                  <span
                    className="extra-label"
                    style={{ width: "200px", display: "inline-block" }}
                  >
                    First Name :
                  </span>
                  <span>Arun</span>
                  <br />
                  <br />
                  <span
                    className="extra-label"
                    style={{ width: "200px", display: "inline-block" }}
                  >
                    Last Name :
                  </span>
                  <span>Sharma</span>
                  <br />
                  <br />
                  <span
                    className="extra-label"
                    style={{ width: "200px", display: "inline-block" }}
                  >
                    Email :
                  </span>
                  <span>arunsharma@gmail.com</span>
                  <br />
                  <br />
                  <span
                    className="extra-label"
                    style={{ width: "200px", display: "inline-block" }}
                  >
                    Gender :
                  </span>
                  <span>Male</span>
                  <br />
                  <br />
                  <span
                    className="extra-label"
                    style={{ width: "200px", display: "inline-block" }}
                  >
                    Mobile Number :
                  </span>
                  <span>9999999999</span>
                  <br />
                  <br />
                  <span
                    className="extra-label"
                    style={{ width: "200px", display: "inline-block" }}
                  >
                    DOB :
                  </span>
                  <span>Dec 19, 1990</span>
                  <br />
                  <br />
                  <span
                    className="extra-label"
                    style={{ width: "200px", display: "inline-block" }}
                  >
                    Location :
                  </span>
                  <span>India</span>
                  <br />
                  <br />
                </form>
              )}

              {activeFormPage === 1 && (
                <form>
                  <h3>Notifications</h3>
                  <input type="checkbox" />{" "}
                  <span>Allow Desktop Notifications</span>
                  <br />
                  <br />
                  <input type="checkbox" /> <span>Enable Notifications</span>
                  <br />
                  <br />
                  <input type="checkbox" />{" "}
                  <span>Get notification for my own activity</span>
                  <br />
                  <br />
                  <input type="checkbox" /> <span>DND</span>
                  <br />
                  <br />
                  <h3>Deactive Account</h3>
                  <input type="radio" name="deactivate-account" />
                  <span>I have a privacy concern</span>
                  <br />
                  <br />
                  <input type="radio" name="deactivate-account" />
                  <span>This is temporary </span>
                  <br />
                  <br />
                  <input type="radio" name="deactivate-account" />
                  <span>Other</span>
                  <br />
                  <br />
                  <PanelButton className="add-page-btn">
                    DEACTIVATE ACCOUNT
                  </PanelButton>
                  <h3>Delete Account</h3>
                  <input type="radio" name="delete-account" />
                  <span>No longer usable</span>
                  <br />
                  <br />
                  <input type="radio" name="delete-account" />
                  <span>Want to switch on other account</span>
                  <br />
                  <br />
                  <input type="radio" name="delete-account" />
                  <span>Other</span>
                  <br />
                  <br />
                  <PanelButton className="add-page-btn">
                    DELETE ACCOUNT
                  </PanelButton>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
