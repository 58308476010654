import Axios from "axios";
import React, { useContext,useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

import "./AuthForm.css";

export default function AuthForm(props) {

  const [loginFormData,setLoginFormData] = useState({
      UserName:"",
      Password:""
  })
  
  const [invalidCredentials,setInvalidCredentials] = useState(false);


  const auth = useContext(AuthContext);
  
  const loginbutton = useRef(null);

  const InputChangeHandler = (event)=>{
     if(event.target.name === "username"){
        setLoginFormData((prev)=>{
            return ({...prev,UserName:event.target.value});
        })
     }else if(event.target.name === "password"){
        setLoginFormData((prev)=>{
            return ({...prev,Password:event.target.value});
        })
     }
  }

  function formSubmitHandler(event){
      event.preventDefault();

      const sendReq = async ()=>{
          
          try{
          loginbutton.current.innerHTML = "<div class = 'loading-spinner'></div>";  
          const response = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/users/login`,loginFormData);
          const {userID,userEmail,userName,userRole} = response.data;
          auth.login(userID,userEmail,userName,userRole);
          loginbutton.current.innerHTML = "Login";  
          }catch(err){
             setInvalidCredentials(true);
          }
      }

      sendReq();
  }
  return (
      <div className="auth-pop-up-form">
    
          <form className = "auth-pop-up-form-main" onSubmit = {formSubmitHandler}>
            <p className = "auth-pop-up-heading">LOGIN</p>
            <input
              name = "username"
              className="auth-pop-input"
              placeholder="Username or Email"
              onChange = {InputChangeHandler}
              value = {loginFormData.UserName}
              required
            />
            <input
              name = "password"
              className="auth-pop-input"
              placeholder="Password"
              type="password"
              onChange = {InputChangeHandler}
              value = {loginFormData.Password}
              required
            />
            {invalidCredentials && <p className = "invalid-cred-text">Invalid UserName or Password</p>}
            <button className = "auth-pop-button" ref = {loginbutton}>
                Login
            </button>
          </form>
        
      </div>
  );
}
