import React from "react";

import "./Textarea.css";

export default function Textarea(props) {
  return (
    <div className="form-input-container">
      <span className="form-input-label">{props.label}</span>
      <textarea
        rows={props.rows}
        name={props.name}
        value={props.value}
        type={props.type}
        placeholder={props.placeholder}
        className={`admin-panel-form-textarea ${props.className}`}
        onChange={props.onChange}
      />
    </div>
  );
}
