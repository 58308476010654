import React from "react";
import StatusCard from "./components/StatusCard";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import PaymentIcon from "@material-ui/icons/Payment";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import Table from "../../Tables/Table";
import TableHeading from "../../Tables/TableHeading";
import TableRow from "../../Tables/TableRow";
import TableCell from "../../Tables/TableCell";

import "./Home.css";
import PanelButton from "../../Buttons/PanelButton";

export default function Home(props) {
  const markValueData = {
    labels: [100, 200, 300, 400, 500, 600, 700, 800],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "#3368C6",
        borderColor: "#3368C6",
        borderWidth: 1,
        hoverBackgroundColor: "#00FFFF",
        hoverBorderColor: "#00FFFF",
        data: [2.5, 3, 3, 0.9, 1.3, 1.8, 3.8, 1.5],
        barPercentage: 0.5,
        barThickness: 10,
      },
      {
        label: "My Second dataset",
        backgroundColor: "#bfbfbf",
        borderColor: "#bfbfbf",
        borderWidth: 1,
        hoverBackgroundColor: "#969696",
        hoverBorderColor: "#969696",
        data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
        barPercentage: 0.38,
      },
    ],
  };

  const revenueData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Revenue",
        backgroundColor: "rgba(51, 104, 198,0.2)",
        borderColor: "rgb(51, 104, 198)",
        borderWidth: 3,
        hoverBackgroundColor: "#0099ff",
        hoverBorderColor: "#0099ff",
        data: [60, 78, 60, 89, 76, 87, 47],
      },
    ],
  };

  const buyData = {
    labels: [0, 10, 20, 30, 40, 50],
    datasets: [
      {
        label: "My First dataset",
        borderColor: "#FF8084",
        backgroundColor: "transparent",
        borderWidth: 3,
        hoverBackgroundColor: "#0099ff",
        hoverBorderColor: "#0099ff",
        data: [0, 30, 40, 10, 86, 40],
      },
      {
        label: "My Second dataset",
        borderColor: "#3368C6",
        backgroundColor: "transparent",
        borderWidth: 3,
        hoverBorderColor: "#969696",
        data: [20, 5, 80, 10, 100, 15],
      },
      {
        label: "My third dataset",
        borderColor: "#A5A5A5",
        backgroundColor: "transparent",
        borderWidth: 3,
        hoverBorderColor: "#969696",
        data: [0, 50, 20, 0, 30, 27],
      },
    ],
  };

  const Salesdata = {
    labels: ["Germany", "Brasil", "United Kingdom", "Australia", "Canada"],
    datasets: [
      {
        label: "# of Votes",
        data: [25, 10, 34, 5, 25],
        backgroundColor: [
          "#FF8084",
          "#3368C6",
          "#A5A5A5",
          "#F0B54D",
          "#81BA00",
        ],
        borderColor: ["#FF8084", "#3368C6", "#A5A5A5", "#F0B54D", "#81BA00"],
        borderWidth: 1,
      },
    ],
  };

  return (
    <React.Fragment>
      <h2 className="dashboard-heading">DASHBOARD</h2>
      <div className="status-cards-container">
        <StatusCard
          icon="sales"
          color="#FFBC58"
          mainContent="₹ 0.00"
          subContent="Earnings this month"
        />
        <StatusCard
          icon="commission"
          color="#3368C6"
          mainContent="₹ 0.00"
          subContent="Products this month"
        />
        <StatusCard
          icon="items"
          color="#FF8084"
          mainContent="₹ 0.00"
          subContent="Messages this month"
        />
        <StatusCard
          icon="orders"
          color="#A5A5A5"
          mainContent="₹ 0.00"
          subContent="New vendors this month"
        />
      </div>
      <br />
      <br />
      <br />

      <div className="dual-chart-container">
        <div className="chart-container">
          <h3>Market Value</h3>
          <Bar
            data={markValueData}
            height={350}
            width={500}
            options={{
              maintainAspectRatio: false,
              responsive: true,
            }}
          />
        </div>
        <div className="table-container">
          <h3>Latest Orders</h3>
          <Table className="inner-table">
            <TableRow>
              <TableHeading>Order Id</TableHeading>
              <TableHeading>Order Total</TableHeading>
              <TableHeading>Payment Method</TableHeading>
              <TableHeading>Status</TableHeading>
            </TableRow>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>₹ 120</TableCell>
              <TableCell>Bank Transfers</TableCell>
              <TableCell>Delivered</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>2</TableCell>
              <TableCell>₹ 120</TableCell>
              <TableCell>Bank Transfers</TableCell>
              <TableCell>Delivered</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>3</TableCell>
              <TableCell>₹ 120</TableCell>
              <TableCell>Bank Transfers</TableCell>
              <TableCell>Delivered</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>4</TableCell>
              <TableCell>₹ 120</TableCell>
              <TableCell>Bank Transfers</TableCell>
              <TableCell>Delivered</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>5</TableCell>
              <TableCell>₹ 120</TableCell>
              <TableCell>Bank Transfers</TableCell>
              <TableCell>Delivered</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>6</TableCell>
              <TableCell>₹ 120</TableCell>
              <TableCell>Bank Transfers</TableCell>
              <TableCell>Delivered</TableCell>
            </TableRow>
          </Table>
          <Link className="link-container" to="/">
            <PanelButton>VIEW ALL ORDERS</PanelButton>
          </Link>
        </div>
      </div>
      <div className="store-status-section">
        <div className="store-status-dual-card">
          <div className="store-status-card">
            <p className="store-status-card-heading">Total Sales</p>
            <p className="store-status-percent">
              42 %{" "}
              <KeyboardArrowUpIcon
                style={{
                  color: "#FF8084",
                  fontSize: "40px",
                  transform: "translateY(5px)",
                }}
              />
            </p>
            <div className="store-status-card-mid-div">
              <p className="store-status-mid-text">Sales Last Month</p>
              <p className="store-status-big-text">2154</p>
              <p className="store-status-small-text">
                0.13%{" "}
                <KeyboardArrowUpIcon
                  style={{
                    color: "rgb(143, 143, 143)",
                    fontSize: "20px",
                    transform: "translateY(5px)",
                  }}
                />
              </p>
              <div
                className="store-status-card-icon-container"
                style={{ backgroundColor: "#FF8084" }}
              >
                <ShoppingBasketIcon
                  style={{ color: "white", fontSize: "26px" }}
                />
              </div>
            </div>
            <p className="store-status-mid-text">Gross sales of August</p>
            <p className="store-status-small-text">
              Lorem Ipsum is simply dummy text of the printing and typesetting
            </p>
          </div>
          <div className="store-status-card">
            <p className="store-status-card-heading">Total Purchase</p>
            <p className="store-status-percent">
              20 %{" "}
              <KeyboardArrowUpIcon
                style={{
                  color: "#3368C6",
                  fontSize: "40px",
                  transform: "translateY(5px)",
                }}
              />
            </p>
            <div className="store-status-card-mid-div">
              <p className="store-status-mid-text">Monthly Purchase</p>
              <p className="store-status-big-text">2154</p>
              <p className="store-status-small-text">
                0.8%{" "}
                <KeyboardArrowUpIcon
                  style={{
                    color: "rgb(143, 143, 143)",
                    fontSize: "20px",
                    transform: "translateY(5px)",
                  }}
                />
              </p>
              <div
                className="store-status-card-icon-container"
                style={{ backgroundColor: "#3368C6" }}
              >
                <PaymentIcon style={{ color: "white", fontSize: "26px" }} />
              </div>
            </div>
            <p className="store-status-mid-text">Avg Gross purchase</p>
            <p className="store-status-small-text">
              Lorem Ipsum is simply dummy text of the printing and typesetting
            </p>
          </div>
        </div>
        <div className="store-status-dual-card">
          <div className="store-status-card">
            <p className="store-status-card-heading">Total cash transaction</p>
            <p className="store-status-percent">
              28 %{" "}
              <KeyboardArrowUpIcon
                style={{
                  color: "#FFC062",
                  fontSize: "40px",
                  transform: "translateY(5px)",
                }}
              />
            </p>
            <div className="store-status-card-mid-div">
              <p className="store-status-mid-text">Cash on Hand</p>
              <p className="store-status-big-text">4672</p>
              <p className="store-status-small-text">
                0.25%{" "}
                <KeyboardArrowUpIcon
                  style={{
                    color: "rgb(143, 143, 143)",
                    fontSize: "20px",
                    transform: "translateY(5px)",
                  }}
                />
              </p>
              <div
                className="store-status-card-icon-container"
                style={{ backgroundColor: "#FFC062" }}
              >
                <ShoppingCartIcon
                  style={{ color: "white", fontSize: "26px" }}
                />
              </div>
            </div>
            <p className="store-status-mid-text">Details about cash</p>
            <p className="store-status-small-text">
              Lorem Ipsum is simply dummy text of the printing and typesetting
            </p>
          </div>

          <div className="store-status-card">
            <p className="store-status-card-heading">Daily Deposits</p>
            <p className="store-status-percent">
              75 %{" "}
              <KeyboardArrowUpIcon
                style={{
                  color: "#A5A5A5",
                  fontSize: "40px",
                  transform: "translateY(5px)",
                }}
              />
            </p>
            <div className="store-status-card-mid-div">
              <p className="store-status-mid-text">Security Deposits</p>
              <p className="store-status-big-text">5782</p>
              <p className="store-status-small-text">
                0.25%{" "}
                <KeyboardArrowUpIcon
                  style={{
                    color: "rgb(143, 143, 143)",
                    fontSize: "20px",
                    transform: "translateY(5px)",
                  }}
                />
              </p>
              <div
                className="store-status-card-icon-container"
                style={{ backgroundColor: "#A5A5A5" }}
              >
                <CalendarTodayIcon
                  style={{ color: "white", fontSize: "26px" }}
                />
              </div>
            </div>
            <p className="store-status-mid-text">Gross sales of June</p>
            <p className="store-status-small-text">
              Lorem Ipsum is simply dummy text of the printing and typesetting
            </p>
          </div>
        </div>
      </div>

      <div className="full-width-chart-container">
        <h3>Buy / Sales</h3>
        <Line
          data={buyData}
          height={350}
          width={500}
          options={{
            maintainAspectRatio: false,
            responsive: true,
          }}
        />
      </div>

      <div className="dual-chart-container">
        <div className="table-container">
          <h3>Products Cart</h3>
          <Table className="inner-table">
            <TableRow>
              <TableHeading>Details</TableHeading>
              <TableHeading>Quantity</TableHeading>
              <TableHeading>Status</TableHeading>
              <TableHeading>Price</TableHeading>
            </TableRow>
            <TableRow>
              <TableCell>Simply dummy text of the printing </TableCell>
              <TableCell>2</TableCell>
              <TableCell>Pending</TableCell>
              <TableCell>₹ 100</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Simply dummy text of the printing </TableCell>
              <TableCell>2</TableCell>
              <TableCell>Pending</TableCell>
              <TableCell>₹ 100</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Simply dummy text of the printing </TableCell>
              <TableCell>2</TableCell>
              <TableCell>Pending</TableCell>
              <TableCell>₹ 100</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Simply dummy text of the printing </TableCell>
              <TableCell>2</TableCell>
              <TableCell>Pending</TableCell>
              <TableCell>₹ 100</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Simply dummy text of the printing </TableCell>
              <TableCell>2</TableCell>
              <TableCell>Pending</TableCell>
              <TableCell>₹ 100</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Simply dummy text of the printing </TableCell>
              <TableCell>2</TableCell>
              <TableCell>Pending</TableCell>
              <TableCell>₹ 100</TableCell>
            </TableRow>
          </Table>
        </div>

        <div className="table-container">
          <h3>Employee Status</h3>
          <Table className="inner-table">
            <TableRow>
              <TableHeading>Name</TableHeading>
              <TableHeading>Designation</TableHeading>
              <TableHeading>Skill Level</TableHeading>
              <TableHeading>Experience</TableHeading>
            </TableRow>
            <TableRow>
              <TableCell>Ramesh Sharma</TableCell>
              <TableCell>Developer</TableCell>
              <TableCell>40%</TableCell>
              <TableCell>3 Year</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ramesh Sharma</TableCell>
              <TableCell>Developer</TableCell>
              <TableCell>40%</TableCell>
              <TableCell>3 Year</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ramesh Sharma</TableCell>
              <TableCell>Developer</TableCell>
              <TableCell>40%</TableCell>
              <TableCell>3 Year</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ramesh Sharma</TableCell>
              <TableCell>Developer</TableCell>
              <TableCell>40%</TableCell>
              <TableCell>3 Year</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ramesh Sharma</TableCell>
              <TableCell>Developer</TableCell>
              <TableCell>40%</TableCell>
              <TableCell>3 Year</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ramesh Sharma</TableCell>
              <TableCell>Developer</TableCell>
              <TableCell>40%</TableCell>
              <TableCell>3 Year</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ramesh Sharma</TableCell>
              <TableCell>Developer</TableCell>
              <TableCell>40%</TableCell>
              <TableCell>3 Year</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ramesh Sharma</TableCell>
              <TableCell>Developer</TableCell>
              <TableCell>40%</TableCell>
              <TableCell>3 Year</TableCell>
            </TableRow>
          </Table>
        </div>
      </div>

      <div className="dual-chart-container">
        <div className="chart-container">
          <h3>Sales Status</h3>
          <div className="small-dual-chat-container">
            <div className="small-chart">
              <h4>Orders by location</h4>
              <Doughnut
                data={Salesdata}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  legend: {
                    display: false,
                  },
                }}
              />
            </div>
            <div className="small-chart">
              <h4>Sales by location</h4>
              <Pie
                data={Salesdata}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  legend: {
                    display: false,
                  },
                }}
              />
            </div>{" "}
          </div>
        </div>
        <div className="chart-container">
          <h3>Revenue for last month</h3>
          <Line
            data={revenueData}
            height={350}
            width={500}
            options={{
              maintainAspectRatio: false,
              responsive: true,
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
