import MaterialTable from "material-table";
import React from "react";
import { tableIcons } from "../../../TableIcons";

import "./MenuList.css";

export default function MenuList(props) {
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">MENU LIST</h2>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Id", field: "id" },
          { title: "Name", field: "name" },
          { title: "Status", field: "status" },
          { title: "Created On", field: "created_on" },
        ]}
        data={[
          {
            id: "#M01",
            name: "Product Menu",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#M02",
            name: "Category Menu",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#M03",
            name: "Subcategory Menu",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#M04",
            name: "Sales Menu",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#M05",
            name: "Vendor Menu",
            status: "active",
            created_on: "Sep 5, 2020",
          },
          {
            id: "#M06",
            name: "Category Menu",
            status: "active",
            created_on: "Sep 5, 2020",
          },
        ]}
        actions={[
          {
            icon: "delete",
            iconProps: { style: { color: "#E4566E" } },
            tooltip: "Delete",
            onClick: (event, rowData) => {
              // Do save operation
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Menu Lists"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
