import React from "react";

import "./PanelButton.css";

export default function PanelButton(props) {
  return (
    <button className={`admin-panel-button ${props.className}`} onClick = {props.onClick}>
      {props.children}
    </button>
  );
}
