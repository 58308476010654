import Axios from "axios";
import React, { useState } from "react";

import Input from "../../../../Form/components/Input";
import PanelButton from "../../../Buttons/PanelButton";

import "./AddVendor.css";

export default function AddUser(props) {
  const [activeFormPage, setActiveFormPage] = useState(0);

  function pageClickHandler(event) {
    const id = event.target.id;
    const activePage = parseInt(id.substring(id.length - 1));

    setActiveFormPage(activePage);
  }

  const [formData,setFormData] = useState({
     Name:"",
     Email:"",
     StoreName:"",
     Mobile:"",
     EmployeeArea:""
  })

  const formChangeHandler = (event)=>{
    let name = event.target.name;

    let value = event.target.value;

    if(name === "full-name"){
      setFormData((prev)=>{
        return {...prev,Name:value};
      })
    }else if(name === "email"){
      setFormData((prev)=>{
        return {...prev,Email:value};
      })
    }else if(name === "store-name"){
      setFormData((prev)=>{
        return {...prev,StoreName:value};
      })
    }else if(name === "mobile"){
      setFormData((prev)=>{
        return {...prev,Mobile:value};
      })
    }else if(name === "employee-area"){
      setFormData((prev)=>{
        return {...prev,EmployeeArea:value};
      })
    }
  }


  function formSubmitHandler(e){
    e.preventDefault();
    const sendReq = async ()=>{
      const response = await Axios.post(process.env.REACT_APP_BACKEND_URL+"/api/distributors/add-distributor",formData);
      alert(response.data);
    }

    sendReq();
  }



  return (
    <div className="page-container">
      <h2 className="dashboard-heading">CREATE DISTRIBUTOR</h2>

      <div className="multi-page-form">
        <div className="multipage-controller">
          <ul className="multipage-controller-list">
            <li
              className={
                activeFormPage === 0 && "multipage-controller-list-active"
              }
              id="multi-page-form-0"
              onClick={pageClickHandler}
            >
              Account
            </li>
          </ul>
        </div>
        <div className="multi-page-main-form-container">
          {activeFormPage === 0 && (
            <form onSubmit = {formSubmitHandler}>
              <Input label="Full Name " name = "full-name" value = {formData.Name} onChange = {formChangeHandler}/>
              <Input label="Email " type="email" name = "email" value = {formData.Email} onChange = {formChangeHandler}/>
              <Input label = "Store Name "  name = "store-name" value = {formData.StoreName} onChange = {formChangeHandler}/>
              <Input label = "Mobile Number " type = "number" name = "mobile" value = {formData.Mobile} onChange = {formChangeHandler}/>
              <Input label = "Employee area " name = "employee-area" value = {formData.EmployeeArea} onChange = {formChangeHandler}/>
              <PanelButton className="add-page-btn">SAVE</PanelButton>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
