import MaterialTable from "material-table";
import React from "react";
import { tableIcons } from "../../TableIcons";

import "./Invoice.css";

export default function Invoice(props) {
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">Invoice</h2>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "No", field: "no" },
          { title: "Invoice", field: "invoice" },
          { title: "Date", field: "date" },
          { title: "Amount", field: "amount" },
          { title: "Tax", field: "tax" },
          { title: "Total", field: "total" },
        ]}
        data={[
          {
            no: "1",
            invoice: "50764",
            date: "10/02/18",
            amount: 816,
            tax: 65.28,
            total: 884.16,
          },
          {
            no: "1",
            invoice: "50764",
            date: "10/02/18",
            amount: 816,
            tax: 65.28,
            total: 884.16,
          },
          {
            no: "1",
            invoice: "50764",
            date: "10/02/18",
            amount: 816,
            tax: 65.28,
            total: 884.16,
          },
          {
            no: "1",
            invoice: "50764",
            date: "10/02/18",
            amount: 816,
            tax: 65.28,
            total: 884.16,
          },
          {
            no: "1",
            invoice: "50764",
            date: "10/02/18",
            amount: 816,
            tax: 65.28,
            total: 884.16,
          },
          {
            no: "1",
            invoice: "50764",
            date: "10/02/18",
            amount: 816,
            tax: 65.28,
            total: 884.16,
          },
          {
            no: "1",
            invoice: "50764",
            date: "10/02/18",
            amount: 816,
            tax: 65.28,
            total: 884.16,
          },
          {
            no: "1",
            invoice: "50764",
            date: "10/02/18",
            amount: 816,
            tax: 65.28,
            total: 884.16,
          },
        ]}
        actions={[
          {
            icon: "delete",
            iconProps: { style: { color: "#E4566E" } },
            tooltip: "Delete",
            onClick: (event, rowData) => {
              // Do save operation
            },
          },
          (rowData) => ({
            icon: "edit",
            iconProps: { style: { color: "#28A757" } },
            tooltip: "Edit",
            onClick: (event, rowData) => {},
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Invoice List"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
