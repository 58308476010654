import React from "react";

import "./TestimonialCard.css";
import Invertedcommas from "../../../Images/inverted coma.svg";

export default function TestimonialCard(props){
    return (
        <div className = "testimonial-container">
           <div className = "testimonial-card">
             <img className = "inverted-commas-image" src = {Invertedcommas} alt = "inverted-commas"/>
             {props.content}
           </div>
           <div className = "testimonial-person">
              <img className = "person-image" src = {props.personImage} alt = "person"/>
              <p className = "person-name">{props.personName}</p>
           </div>
        </div>
    );
}