import Axios from "axios";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { tableIcons } from "../../../TableIcons";

import "./ManufacturerList.css";

export default function ManufacturerList(props) {

  const [manufacturers,setManufacturers] = useState([]);
  const [products, setProducts] = useState([]);
  const [renderListManufacturers,setRenderListManufacturers] = useState([]);

  
  useEffect(()=>{
   
  const sendReq = async () => {
     const response = await Axios.get(
         process.env.REACT_APP_BACKEND_URL + "/api/products/get-products"
        );
        setProducts(response.data);
      };
    
      sendReq();


   const getManufacturers = async ()=>{
       const response = await Axios.get(process.env.REACT_APP_BACKEND_URL+"/api/manufacturer/get-manufacturer");

       setManufacturers(response.data);
   }

   getManufacturers();

  },[])

  useEffect(()=>{
    for(let i = 0;i<manufacturers.length;i++){
        let productNames = "";
        let temp = manufacturers[i].Products.split(",");
        for(let k = 0;k<temp.length-1;k++){
            for(let j = 0;j<products.length;j++){
               if(products[j].Id === temp[k]){
                   productNames+= products[j].Name+", ";
               }
            }
        }
    
       setRenderListManufacturers((prev)=>{
          return ( [...prev,{
               Name:manufacturers[i].Name,
               Products:productNames,
               Address:manufacturers[i].Address,
               Email:manufacturers[i].Email,
               Mobile:manufacturers[i].Mobile,
               PaymentLeft:manufacturers[i].PaymentLeft
           }])
       }) 
    }
  },[manufacturers,products])
  
    
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">MANUFACTURER LIST</h2>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Name", field: "Name" },
          { title: "Products", field: "Products" },
          { title: "Address", field: "Address" },
          { title: "Email", field: "Email" },
          { title: "Mobile", field: "Mobile" },
          { title: "Payment Left, ₹", field: "PaymentLeft" },
        ]}
        data={renderListManufacturers}
        title="Manufacturer Details"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
