import MaterialTable from "material-table";
import React from "react";
import { tableIcons } from "../../../TableIcons";

import "./Orders.css";

export default function DigitalProductList(props) {
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">ORDERS</h2>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Order Id", field: "id" },
          {
            field: "image",
            title: "Image",
            render: (rowData) => (
              <div className="orders-image-container">
                <img
                  src={rowData.image[0]}
                  style={{ width: 40, margin: 5 }}
                  alt="product"
                />
                <img
                  src={rowData.image[1]}
                  style={{ width: 40, margin: 5 }}
                  alt="product"
                />
              </div>
            ),
          },
          { title: "Status", field: "status" },
          { title: "Payment", field: "payment" },
          { title: "Order Status", field: "order_status" },
          { title: "Date", field: "date" },
          { title: "Total", field: "total" },
        ]}
        data={[
          {
            id: "#5140",
            image: [
              "https://react.pixelstrap.com/multikart-admin/static/media/25.4faa1f6e.jpg",
              "https://react.pixelstrap.com/multikart-admin/static/media/13.af2706f5.jpg",
            ],
            status: "Cash on Delivery",
            payment: "Debit Card",
            order_status: "Processing",
            date: "Dec 10, 18",
            total: "₹20120",
          },
          {
            id: "#5140",
            image: [
              "https://react.pixelstrap.com/multikart-admin/static/media/25.4faa1f6e.jpg",
              "https://react.pixelstrap.com/multikart-admin/static/media/13.af2706f5.jpg",
            ],
            status: "Cash on Delivery",
            payment: "Debit Card",
            order_status: "Processing",
            date: "Dec 10, 18",
            total: "₹20120",
          },
          {
            id: "#5140",
            image: [
              "https://react.pixelstrap.com/multikart-admin/static/media/25.4faa1f6e.jpg",
              "https://react.pixelstrap.com/multikart-admin/static/media/13.af2706f5.jpg",
            ],
            status: "Cash on Delivery",
            payment: "Debit Card",
            order_status: "Processing",
            date: "Dec 10, 18",
            total: "₹20120",
          },
          {
            id: "#5140",
            image: [
              "https://react.pixelstrap.com/multikart-admin/static/media/25.4faa1f6e.jpg",
              "https://react.pixelstrap.com/multikart-admin/static/media/13.af2706f5.jpg",
            ],
            status: "Cash on Delivery",
            payment: "Debit Card",
            order_status: "Processing",
            date: "Dec 10, 18",
            total: "₹20120",
          },
          {
            id: "#5140",
            image: [
              "https://react.pixelstrap.com/multikart-admin/static/media/25.4faa1f6e.jpg",
              "https://react.pixelstrap.com/multikart-admin/static/media/13.af2706f5.jpg",
            ],
            status: "Cash on Delivery",
            payment: "Debit Card",
            order_status: "Processing",
            date: "Dec 10, 18",
            total: "₹20120",
          },
          {
            id: "#5140",
            image: [
              "https://react.pixelstrap.com/multikart-admin/static/media/25.4faa1f6e.jpg",
              "https://react.pixelstrap.com/multikart-admin/static/media/13.af2706f5.jpg",
            ],
            status: "Cash on Delivery",
            payment: "Debit Card",
            order_status: "Processing",
            date: "Dec 10, 18",
            total: "₹20120",
          },
        ]}
        actions={[
          {
            icon: "delete",
            iconProps: { style: { color: "#E4566E" } },
            tooltip: "Delete",
            onClick: (event, rowData) => {
              // Do save operation
            },
          },
          (rowData) => ({
            icon: "edit",
            iconProps: { style: { color: "#28A757" } },
            tooltip: "Edit",
            onClick: (event, rowData) => {},
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Manage Order"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
