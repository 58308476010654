import React, { useState } from "react";

import Input from "../../../../Form/components/Input";
import Textarea from "../../../../Form/components/Textarea";
import PanelButton from "../../../Buttons/PanelButton";

import "./AddPage.css";

export default function AddPage(props) {
  const [activeFormPage, setActiveFormPage] = useState(0);

  function pageClickHandler(event) {
    const id = event.target.id;
    const activePage = parseInt(id.substring(id.length - 1));

    setActiveFormPage(activePage);
  }

  return (
    <div className="page-container">
      <h2 className="dashboard-heading">CREATE PAGE</h2>

      <div className="multi-page-form">
        <div className="multipage-controller">
          <ul className="multipage-controller-list">
            <li
              className={
                activeFormPage === 0 && "multipage-controller-list-active"
              }
              id="multi-page-form-0"
              onClick={pageClickHandler}
            >
              General
            </li>
            <li
              className={
                activeFormPage === 1 && "multipage-controller-list-active"
              }
              id="multi-page-form-1"
              onClick={pageClickHandler}
            >
              SEO
            </li>
          </ul>
        </div>
        <div className="multi-page-main-form-container">
          {activeFormPage === 0 && (
            <form>
              <Input name="product-name" label="Product Name " />
              <Textarea
                rows={10}
                name="description"
                label="Add Description "
              />
              <span className="extra-label">Status :</span>
              <input type="checkbox" />
              <span>Enable the coupon</span>
              <br />
              <br />
              <PanelButton className="add-page-btn">SAVE</PanelButton>
            </form>
          )}

          {activeFormPage === 1 && (
            <form>
              <Input label="Meta Titile " />
              <Textarea rows={5} label="Meta Description " />
              <PanelButton className="add-page-btn">SAVE</PanelButton>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
