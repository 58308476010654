import React from "react";
import { Bar, Line } from "react-chartjs-2";
import MaterialTable from "material-table";
import { tableIcons } from "../../TableIcons";

import "./Reports.css";

export default function Home(props) {
  const salesummaryData = {
    labels: [0, 10, 20, 30, 40, 50],
    datasets: [
      {
        label: "Sales",
        backgroundColor: "transparent",
        borderColor: "#A5A5A5",
        borderWidth: 3,
        data: [5, 30, 20, 40, 50, 20],
      },
    ],
  };

  const employeeSatisfactionData = {
    labels: ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "",
        backgroundColor: "rgba(19, 201, 202,0.2)",
        borderColor: "#3368C6",
        borderWidth: 3,
        data: [60, 78, 60, 89, 76, 87, 47],
      },
    ],
  };

  const performanceData = {
    labels: [2013, 2014, 2015, 2016],
    datasets: [
      {
        label: "Expenses",
        backgroundColor: "rgba(19, 201, 202,0.2)",
        borderColor: "rgb(19, 201, 202)",
        borderWidth: 3,
        data: [1000, 1170, 660, 1030],
      },
      {
        label: "Sales",
        backgroundColor: "rgba(145, 162, 168,0.2)",
        borderColor: "rgb(145, 162, 168)",
        borderWidth: 3,
        data: [400, 550, 1120, 540],
      },
    ],
  };

  const salesData = {
    labels: [100, 200, 300, 400, 500, 600, 700, 800],
    backgroundColor: "#bfbfbf",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    hoverBackgroundColor: "#969696",
    hoverBorderColor: "#969696",
    barPercentage: 0.38,
    datasets: [
      {
        label: "sales/purchase",
        backgroundColor: "rgb(145, 162, 168)",
        data: [3.8, 1.8, 4.3, 2.3, 3.6, 2.8, 2.8, 2.8],
      },
    ],
  };

  const salesReturnData = {
    labels: [0, 10, 20, 30, 40, 50, 60, 70, 80],
    backgroundColor: "transparent",
    borderColor: "$bfbfbf",
    borderWidth: 3,
    datasets: [
      {
        label: "sales/purchase return",
        data: [60, 10, 40, 30, 80, 30, 20, 90, 0],
      },
    ],
  };
  return (
    <React.Fragment>
      <h2 className="dashboard-heading">REPORTS</h2>

      <div className="dual-chart-container">
        <div className="chart-container">
          <h3>Sales Summary</h3>
          <Line
            data={salesummaryData}
            height={350}
            width={500}
            options={{
              maintainAspectRatio: false,
              responsive: true,
            }}
          />
        </div>
        <div className="chart-container">
          <h1 className="margin-null">75%</h1>
          <p className="margin-null">Employees Satisfied</p>
          <Line
            data={employeeSatisfactionData}
            height={350}
            width={500}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                yAxes: [
                  {
                    gridLines: {
                      drawBorder: false,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>

      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Name", field: "name" },
          { title: "Transfer Id", field: "t_id" },
          { title: "Date", field: "date" },
          { title: "Total", field: "total" },
        ]}
        data={[
          {
            name: "Sanjay Mahajan",
            t_id: "14783112",
            date: "Sep 2, 2019",
            total: "₹7035",
          },
          {
            name: "Sanjay Mahajan",
            t_id: "14783112",
            date: "Sep 2, 2019",
            total: "₹7035",
          },
          {
            name: "Sanjay Mahajan",
            t_id: "14783112",
            date: "Sep 2, 2019",
            total: "₹7035",
          },
          {
            name: "Sanjay Mahajan",
            t_id: "14783112",
            date: "Sep 2, 2019",
            total: "₹7035",
          },
          {
            name: "Sanjay Mahajan",
            t_id: "14783112",
            date: "Sep 2, 2019",
            total: "₹7035",
          },
          {
            name: "Sanjay Mahajan",
            t_id: "14783112",
            date: "Sep 2, 2019",
            total: "₹7035",
          },
          {
            name: "Sanjay Mahajan",
            t_id: "14783112",
            date: "Sep 2, 2019",
            total: "₹7035",
          },
          {
            name: "Sanjay Mahajan",
            t_id: "14783112",
            date: "Sep 2, 2019",
            total: "₹7035",
          },
          {
            name: "Sanjay Mahajan",
            t_id: "14783112",
            date: "Sep 2, 2019",
            total: "₹7035",
          },
        ]}
        actions={[
          {
            icon: "delete",
            iconProps: { style: { color: "#E4566E" } },
            tooltip: "Delete",
            onClick: (event, rowData) => {
              // Do save operation
            },
          },
          (rowData) => ({
            icon: "edit",
            iconProps: { style: { color: "#28A757" } },
            tooltip: "Edit",
            onClick: (event, rowData) => {},
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Tranfer Report"
      />

      <div className="dual-chart-container">
        <div className="chart-container">
          <h3>Company Performance</h3>
          <Line
            data={performanceData}
            height={350}
            width={500}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              elements: {
                line: {
                  tension: 0,
                },
              },
            }}
          />
        </div>
        <div className="chart-container">
          <h3>Sales / Purchase</h3>
          <Bar
            data={salesData}
            height={100}
            width={200}
            options={{
              maintainAspectRatio: false,
              responsive: true,
            }}
          />
        </div>
      </div>

      <div className="full-width-chart-container">
        <h3>Sales / Purchase Return </h3>
        <Line
          data={salesReturnData}
          height={350}
          width={500}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            elements: {
              line: {
                tension: 0,
              },
            },
          }}
        />
      </div>
    </React.Fragment>
  );
}
