import React from "react";
import InfoWithImage from "../components/Info Section/InfoWithImage";
import visionImage from "../Images/mission.jpg";
import StrategyImage from "../Images/strategy.jpg";
import ResponsibilityImage from "../Images/responsibility.jpg";
import ValuesImage from "../Images/values.jpg";

import "./AboutUs.css";
import { MetaTags } from "react-meta-tags";

export default function AboutUs(props) {
  return (
    <div className="container">
      <MetaTags>
        <meta
          name="keywords"
          content="Synapes Life Sciences, science-based innovation, healthcare issues, healthcare needs, aging population, responsible business, local communities"
        />
        <meta
          name="description"
          content="Synapes life sciences was founded by sandhya group which itself has 25 yrs of experience with pharmaceutical production. we are a science based innovation driven company solving the healthcare issues."
        />
      </MetaTags>

      <InfoWithImage
        heading="Our Mission And Vision"
        image={visionImage}
        left="true"
      >
        <p className="sub-heading">
          Our mission is to discover new ways to improve and extend people's
          lives!
        </p>
        <p className="sub-points">
          We use science-based innovation to address some of society's most
          challenging healthcare issues. We discover and develop breakthrough
          treatments and find new ways to deliver them to as many people as
          possible. We also aim to provide a shareholder return that rewards
          those who invest their money, time and ideas in our company.
        </p>
        <p className="sub-points">
          Our vision is to be a trusted leader in changing the practice of
          medicine.
        </p>
      </InfoWithImage>

      <InfoWithImage
        heading="Our Strategy"
        image={StrategyImage}
        right="true"
        imageClass="about-big-image"
      >
        <p className="sub-small-heading">
          Our mission is to discover new ways to improve and extend people's
          lives!
        </p>
        <p className="sub-points">
          We believe synapes is well prepared for a world with a growing, aging
          population and continuously evolving healthcare needs. We have a clear
          mission, focused strategy and strong culture, all of which we expect
          will support the creation of value over the long term for our company,
          our shareholders and society. Our strategy is to use science-based
          innovation to deliver better patient outcomes. We aim to lead in
          growing areas of healthcare.
        </p>
        <p className="sub-small-heading">Better patient outcomes</p>
        <p className="sub-points">
          We seek to develop medicines and products that can produce positive
          real-world outcomes for patients and healthcare providers. The
          benefits can range from improving the cost-effectiveness of
          high-quality care to pro¬longing lives. We are developing services and
          technologies to augment the benefits of our core products, often in
          collaboration with healthcare providers and technology companies.
        </p>
        <p className="sub-small-heading">Lead in growing areas of healthcare</p>
        <p className="sub-points">
          We aim to develop innovative products in growing areas of healthcare
          where we can make a real difference. We focus on patented medicines,
          critical care and neurological – segments where we have the innovation
          power and global scale necessary to compete effectively.
        </p>
      </InfoWithImage>

      <InfoWithImage
        heading="Corporate Responsibility"
        image={ResponsibilityImage}
        left="true"
        imageClass="about-big-image"
      >
        <p className="sub-small-heading">
          Our commitment: improving health through responsible business.
        </p>
        <p className="sub-points">
          We are committed to creating a culture of integrity and we believe
          that, as a global leader in healthcare, we have a responsibility to
          serve as a role model in how we conduct our business. We focus our
          corporate responsibility work on two areas that underscore our
          mission, expanding access to healthcare and doing business
          responsibly. We care for our associates, contribute to our local
          communities, and strive to operate with high integrity and
          transparency.
        </p>
        <p className="sub-small-heading">
          Our commitment: improving health through responsible business.
        </p>
        <p className="sub-points">
          We are committed to creating a culture of integrity and we believe
          that, as a global leader in healthcare, we have a responsibility to
          serve as a role model in how we conduct our business. We focus our
          corporate responsibility work on two areas that underscore our
          mission, expanding access to healthcare and doing business
          responsibly. We care for our associates, contribute to our local
          communities, and strive to operate with high integrity and
          transparency. We aim to improve global health. Through our business,
          we make an important contribution to society: We discover and develop
          innovative medicines. Novartis collaborates with others to help
          address some of the world’s greatest health challenges. We focus our
          corporate responsibility work on two areas that underscore our mission
          of caring and curing: expanding access to healthcare and doing
          business responsibly.
        </p>
        <p className="sub-small-heading">Expanding access to healthcare</p>
        <p className="sub-points">
          We work to control and eliminate diseases such as malaria and critical
          care diseases new business approaches to reach underserved patients,
          and find new treatments and adaptive solutions to improve health in
          developing countries.
        </p>
        <p className="sub-small-heading">Doing business responsibly</p>
        <p className="sub-points">
          This is a core part of synapes. We are committed to creating a culture
          of integrity and demonstrate ethical leadership.
        </p>
      </InfoWithImage>

      <InfoWithImage
        heading="Corporate Responsibility"
        image={ValuesImage}
        right="true"
        imageClass="about-big-image"
        content="Strong values define our culture and help us execute the Novartis strategy in line with our mission and vision."
      >
        <p className="sub-small-heading">Innovation</p>
        <p className="sub-points">By experimenting and delivering solutions</p>
        <p className="sub-small-heading">Quality</p>
        <p className="sub-points">
          By taking pride in doing ordinary things extraordinarily well
        </p>
        <p className="sub-small-heading">Performance</p>
        <p className="sub-points">
          By prioritizing and making things happen with urgency
        </p>
        <p className="sub-small-heading">Courage</p>
        <p className="sub-points">
          By speaking up, giving and receiving feedback
        </p>
        <p className="sub-small-heading">Courage</p>
        <p className="sub-points">
          By advocating and applying high ethical standards every day
        </p>
      </InfoWithImage>
    </div>
  );
}
