import MaterialTable from "material-table";
import React from "react";
import { tableIcons } from "../../../TableIcons";

import "./ListCoupons.css";

export default function ListCoupons(props) {
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">LIST COUPONS</h2>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Id", field: "id" },
          { title: "Title", field: "title" },
          { title: "Code", field: "code" },
          { title: "Discount", field: "discount" },
          { title: "Status", field: "status" },
        ]}
        data={[
          {
            id: "1087",
            title: " 15% Off",
            code: "Percent10",
            discount: "22%",
            status: "Pending",
          },
          {
            id: "1087",
            title: " 15% Off",
            code: "Percent10",
            discount: "22%",
            status: "Pending",
          },
          {
            id: "1087",
            title: " 15% Off",
            code: "Percent10",
            discount: "22%",
            status: "Pending",
          },
          {
            id: "1087",
            title: " 15% Off",
            code: "Percent10",
            discount: "22%",
            status: "Pending",
          },
          {
            id: "1087",
            title: " 15% Off",
            code: "Percent10",
            discount: "22%",
            status: "Pending",
          },
          {
            id: "1087",
            title: " 15% Off",
            code: "Percent10",
            discount: "22%",
            status: "Pending",
          },
        ]}
        actions={[
          {
            icon: "delete",
            iconProps: { style: { color: "#E4566E" } },
            tooltip: "Delete",
            onClick: (event, rowData) => {
              // Do save operation
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Products Category"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
