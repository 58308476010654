import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";

import "./ProductCard.css";
import Axios from "axios";

export default function ProductCard(props) {

  const [popupactive,setPopupactive] = useState(false);

  const [productactive,setProductactive] = useState(true);

  function productDeleteFrotend(){
    setProductactive(false);
  }
   
  function popupOpener(event){
    setPopupactive(true);
  }


  function popupCloser(event){
    setPopupactive(false);
  }

  function productDeleteHandler(){

    
    const sendDelReq = async ()=>{

     await Axios.delete(process.env.REACT_APP_BACKEND_URL+"/api/products/delete-product",{
        data: {
          Id:props.Id
        }
      });
    }

    sendDelReq();
    popupCloser();
    productDeleteFrotend();



  }



  return (
    <React.Fragment>
    {popupactive && 
    <div className = "delete-prompt-container">

       <div className = "delete-prompt">
           <p className = "delete-prompt-text">Are you sure you want to delete this product?</p>
           <div className = "delete-prompt-button-container">
                <button className = "delete-prompt-delete-button" onClick = {productDeleteHandler}>Delete</button>
                <button className = "delete-prompt-cancel-button" onClick = {popupCloser}>Cancel</button>
           </div>
       </div> 
     
    </div>
    }
    { productactive && 
    <div className={`product-card ${props.className}`}>
      <div className="product-card-image-container">
        <img src={props.image} alt="product" className="product-card-image" />
      </div>
      <div className="product-card-details-container">
        <br />
        <h4>{props.name}</h4>
        <p className="product-card-description">{props.description}</p>
      </div>
      <DeleteIcon className = "delete-icon-product-card" onClick = {popupOpener}/>
    </div>
    }
    </React.Fragment>
  );
}
