import React, { useState } from "react";

import Input from "../../../../Form/components/Input";
import Select from "../../../../Form/components/Select";
import PanelButton from "../../../Buttons/PanelButton";

import "./AddUser.css";

export default function AddUser(props) {
  const [activeFormPage, setActiveFormPage] = useState(0);

  function pageClickHandler(event) {
    const id = event.target.id;
    const activePage = parseInt(id.substring(id.length - 1));

    setActiveFormPage(activePage);
  }

  return (
    <div className="page-container">
      <h2 className="dashboard-heading">CREATE EMPLOYEE</h2>

      <div className="multi-page-form">
        <div className="multipage-controller">
          <ul className="multipage-controller-list">
            <li
              className={
                activeFormPage === 0 && "multipage-controller-list-active"
              }
              id="multi-page-form-0"
              onClick={pageClickHandler}
            >
              Account
            </li>
            <li
              className={
                activeFormPage === 1 && "multipage-controller-list-active"
              }
              id="multi-page-form-1"
              onClick={pageClickHandler}
            >
              Permission
            </li>
          </ul>
        </div>
        <div className="multi-page-main-form-container">
          {activeFormPage === 0 && (
            <form>
              <Input label="First Name " />
              <Input label="Last Name " />
              <Input label="Username " />
              <Input label="Email " type="email" />
              <Input label="Phone Number" type="number" />
              <Select placeholder = "--select--" label = "Employee Role:" options = {["Technical Staff","Sales Executive"]}/>
              <Input label="Password " type="password" />
              <Input label="Confirm Password " type="password" />
              <PanelButton className="add-page-btn">SAVE</PanelButton>
            </form>
          )}

          {activeFormPage === 1 && (
            <form>
              <h3>Category Related Permission</h3>
              <span className="radio-heading">Add Product</span>{" "}
              <input type="radio" name="add-product" />
              <span>Allow</span> <input type="radio" name="add-product" />{" "}
              <span>Deny</span>
              <br />
              <br />
              <span className="radio-heading">Update Product</span>{" "}
              <input type="radio" name="update-product" />
              <span>Allow</span> <input type="radio" name="update-product" />{" "}
              <span>Deny</span>
              <br />
              <br />
              <span className="radio-heading">Delete Product</span>{" "}
              <input type="radio" name="delete-product" />
              <span>Allow</span> <input type="radio" name="delete-product" />{" "}
              <span>Deny</span>
              <br />
              <br />
              <span className="radio-heading">Apply Product</span>{" "}
              <input type="radio" name="apply-product" />
              <span>Allow</span> <input type="radio" name="apply-product" />{" "}
              <span>Deny</span>
              <br />
              <br />
              <h3>Category Related Permission</h3>
              <span className="radio-heading">Add Category</span>{" "}
              <input type="radio" name="add-Category" />
              <span>Allow</span> <input type="radio" name="add-Category" />{" "}
              <span>Deny</span>
              <br />
              <br />
              <span className="radio-heading">Update Category</span>{" "}
              <input type="radio" name="update-Category" />
              <span>Allow</span> <input type="radio" name="update-Category" />{" "}
              <span>Deny</span>
              <br />
              <br />
              <span className="radio-heading">Delete Category</span>{" "}
              <input type="radio" name="delete-Category" />
              <span>Allow</span> <input type="radio" name="delete-Category" />{" "}
              <span>Deny</span>
              <br />
              <br />
              <span className="radio-heading">Apply Category</span>{" "}
              <input type="radio" name="apply-Category" />
              <span>Allow</span> <input type="radio" name="apply-Category" />{" "}
              <span>Deny</span>
              <br />
              <br />
              <PanelButton className="add-page-btn">SAVE</PanelButton>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
