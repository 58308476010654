import Axios from "axios";
import React, { useEffect, useState } from "react";

import Input from "../../../../Form/components/Input";
import PanelButton from "../../../Buttons/PanelButton";
import InputLabel from "../../../../Form/components/InputLabelForForm";

import "./CreateManufacturer.css";

export default function CreateManufacturer(props) {
  const [activeFormPage, setActiveFormPage] = useState(0);

  function pageClickHandler(event) {
    const id = event.target.id;
    const activePage = parseInt(id.substring(id.length - 1));

    setActiveFormPage(activePage);
  }

  const [products, setProducts] = useState([]);

  useEffect(() => {
    const sendReq = async () => {
      const response = await Axios.get(
        process.env.REACT_APP_BACKEND_URL + "/api/products/get-products"
      );
      setProducts(response.data);
    };

    sendReq();
  }, []);

  const [selectedProduct,setSelectedProducts] = useState("Select Product");

  const [allproducts,setAllProducts] = useState([]);

  function selectedProductHandler(event){
     setSelectedProducts(event.target.value);
  }

  function allProductsHandler(){
      setAllProducts((prev)=>{
          return ([...prev,selectedProduct]);
      })

      for(let i = 0;i<products.length;i++){
          if(products[i].Name === selectedProduct){
              setFormData((prev)=>{
                return( {...prev,Products:prev.Products+products[i].Id+","} );
             })
              break;
          }
      }
  }

  const [formData,setFormData] = useState(
      {
          Name:"",
          Products:"",
          Address:"",
          Email:"",
          Mobile:""
      }
  );

  function submitForm(){
      const sendReq = async ()=>{
          const response = await Axios.post(process.env.REACT_APP_BACKEND_URL+"/api/manufacturer/add-manufacturer",formData);
          alert(response.data);
          setFormData( {
            Name:"",
            Products:"",
            Address:"",
            Email:"",
            Mobile:""
        })
          setAllProducts([]);
          setSelectedProducts("Select Product")
      }

      sendReq();
  }

  function formChangeHandler(event){
      if(event.target.name === "name"){
        setFormData((prev)=>{
           return( {...prev,Name:event.target.value} );
        })
      }else if(event.target.name === "address"){
        setFormData((prev)=>{
            return( {...prev,Address:event.target.value} );
         })   
      }else if(event.target.name === "email"){
        setFormData((prev)=>{
            return( {...prev,Email:event.target.value} );
         })
      }else if(event.target.name === "mobile"){
        setFormData((prev)=>{
            return( {...prev,Mobile:event.target.value} );
         }) 
      }
  }

  return (
    <div className="page-container">
      <h2 className="dashboard-heading">CREATE MANUFACTURER</h2>

      <div className="multi-page-form">
        <div className="multipage-controller">
          <ul className="multipage-controller-list">
            <li
              className={
                activeFormPage === 0 && "multipage-controller-list-active"
              }
              id="multi-page-form-0"
              onClick={pageClickHandler}
            >
              Account
            </li>
          </ul>
        </div>
        <div className="multi-page-main-form-container">
          {activeFormPage === 0 && (
            <div>
              <Input label="Name " name = "name" value = {formData.Name} onChange = {formChangeHandler}/>
              <Input label="Address" name = "address" value = {formData.Address} onChange = {formChangeHandler}/>
              <Input label="Email" type="email" name = "email" value = {formData.Email} onChange = {formChangeHandler}/>
              <Input label="Mobile Number" type="number" name = "mobile" value = {formData.Mobile} onChange = {formChangeHandler}/>
              <div className="form-input-container">
                <span className="form-input-label">Choose Product</span>
                <select
                  value = {selectedProduct}
                  className={`admin-panel-form-select`}
                  onChange={selectedProductHandler}
                >
                    <option disabled defaultValue>
                      Select Product
                    </option>
                  {products.map((optionName,index) => {
                    return <option key = {index} value = {optionName.Name}>{optionName.Name}</option>;
                  })}
                </select>
                <PanelButton className="add-page-btn add-btn" onClick = {allProductsHandler}>Add</PanelButton>
              </div>
              <div className = "product-list-display"><InputLabel>Products</InputLabel>{allproducts.map((p,index)=>{
                return (<span key = {index}>{p}</span>);
              })}</div>
              <PanelButton className="add-page-btn" onClick = {submitForm}>SAVE</PanelButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
