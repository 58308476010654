import React from "react";
import InfoWithImage from "../components/Info Section/InfoWithImage";
import ResponsibilityImage from "../Images/ResponsibilityPage.jpg";
import PhilosophyImage from "../Images/philosophy.jpg";
import EhticsImage from "../Images/ethics.jpg";
import ValueImage from "../Images/values.png";

import "./ResponsibilityPage.css";
import { MetaTags } from "react-meta-tags";

export default function ResponsibilityPage(props) {
  return (
    <div className="container">
      <MetaTags>
        <meta
          name="keywords"
          content="Synapes life sciences, socially responsible entrepreneurship, global health, public health challenges, excellent service, mankind  "
        />
      </MetaTags>

      <InfoWithImage
        left="true"
        heading="Our Responsibility"
        content="At Synapes, we believe every individual deserves the opportunity to live the healthiest life possible. That’s why we strive to strengthen health systems, increase access to our medicines and foster socially responsible entrepreneurship to help find sustainable solutions for patients in need around the world. We also realize the power of individuals to drive meaningful change in today’s global health landscape. It's these singular voices that inspire us to make the best use of our people, our products and our funding to address complex public health challenges. Here you will find the stories of our partners, patients, caregivers, colleagues, and others who inspire us with their efforts to change lives and make the world a healthier place."
        image={ResponsibilityImage}
      />

      <InfoWithImage
        right="true"
        heading="Our Philosophy"
        content="At Synapes life sciences, we believe every individual deserves the opportunity to live the healthiest life possible. That’s why we strive to strengthen health systems, increase access to our medicines and foster socially responsible entrepreneurship to help find sustainable solutions for patients in need around the world. We also realize the power of individuals to drive meaningful change in today’s global health landscape. It's these singular voices that inspire us to make the best use of our people, our products and our funding to address complex public health challenges.Here you will find the stories of our partners, patients, caregivers, colleagues, and others who inspire us with their efforts to change lives and make the world a healthier place."
        image={PhilosophyImage}
      />

      <InfoWithImage
        left="true"
        heading="Our Ethics"
        content="We believe that client health is our health, if our client’s are healthy then we are healthy. Not only our client’s but global health is also our concern.Because, if the world is healthy then we can thrive more.Our ethics not only limits itself to pharmaceutical standards, but also into all aspects- Finance & Accounting, Human Resource, Sales and Marketing, Production, Intellectual Property, Knowledge and Skills and also International Business.All this shows that our ethics are always for the betterment of our client’s."
        image={EhticsImage}
      />

      <InfoWithImage
        right="true"
        heading="Our Values"
        imageClass = "about-big-image"
        image={ValueImage}
       >
           <p className="sub-heading">Integrity</p>
          <p className="sub-points">
          Our conduct of business exhibits fairness, honesty, transparency and purposefulness. We share everything with our client’s and hope for their best, and we provide the best to our client’s, we try to give them excellent service with the best price.
          We believe that having strong morals and giving back to the people is what give our business a boost. We believe that always worrying about money is not our business, and that is why we give it back to by various campaigns runned by us.

        </p>

        <p className="sub-heading">Commitment</p>
          <p className="sub-points">
          We are committed to provide the best in this challenging times, we are committed to provide the mankind with betterment in the best possible way. Having good morals and integrity is not everything, because no matter how good your values would be, until you implement it and commit to it, there is no use of it. That is why we at Synapes life science are committed to implement our morals and integrity and give the best to the society, to the humankind.
        </p>

        <p className="sub-heading">Unity</p>
          <p className="sub-points">
          We here at synapes breathe as a team, for us unity is most important, just like a body united works wonders, we as a team work wonders to provide the best. We all know that a company or an association is nothing without its team, we here at Synapes Life Science give our best only because we have a team with unity and which motivates each other every now and then. Staying united is one of our core values which we don't take lightly.
        </p>

        <p className="sub-heading">Responsibility</p>
          <p className="sub-points">
          We are a responsible company working for the betterment of the environment and the fellow mankind. We are responsible for the services that we deliver, the value that we deliver, and that is how we show our credibility, with a name in the industry for the past 25 years tells you how strictly we have maintained our values and took responsibility of everything we delivered. Our clients still come to us every time because they know how much we care for them.
        </p>
       </InfoWithImage>
    </div>
  );
}
