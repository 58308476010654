import React, { useContext } from "react";
import { Route } from "react-router-dom";
import Home from "../components/Pages/Home/Home";
import Orders from "../components/Pages/Sales/Orders/Orders";
import Transactions from "../components/Pages/Sales/Transactions/Transactions";
import Reports from "../components/Pages/Reports/Reports";

import "./AdminPanelRoutes.css";
import ListCoupons from "../components/Pages/Coupons/List Coupons/ListCoupons";
import Invoice from "../components/Pages/Invoice/Invoice";
import VendorList from "../components/Pages/Vendors/Vendor List/VendorList";
import UserList from "../components/Pages/Users/User list/UserList";
import PageList from "../components/Pages/PagesComponents/List Page/PageList";
import MenuList from "../components/Pages/Menus/List Menu/MenuList";
import AddPage from "../components/Pages/PagesComponents/Create Page/AddPage";
import AddCoupon from "../components/Pages/Coupons/Create Coupons/AddCoupon";
import AddMenu from "../components/Pages/Menus/Create Menu/AddMenu";
import AddUser from "../components/Pages/Users/Create User/AddUser";
import AddVendor from "../components/Pages/Vendors/Create Vendor/AddVendor";
import ProfilePage from "../components/Pages/Settings/Profile/ProfilePage";
import Media from "../components/Pages/Media/Media";
import SideBar from "../components/SideBar";
import NavBarAdmin from "../components/NavBarAdmin";
import { AuthContext } from "../../../context/AuthContext";
import AddProduct from "../components/Pages/products/Add Product/AddProduct";
import ProductList from "../components/Pages/products/Product List/ProductList";
import ManufacturerList from "../components/Pages/Manufacturer/List_Manufacturer/ManufacturerList";
import CreateManufacturer from "../components/Pages/Manufacturer/Add Manufacturer/CreateManufacturer";
import PlaceOrder from "../components/Pages/Orders/Place Order/PlaceOrder";
import ListOrders from "../components/Pages/Orders/List Orders/ListOrders";
import Stocks from "../components/Pages/Stocks/Stocks";
import Bill from "../components/Pages/Billing/Bill";

export default function AdminPanelRoutes(props) {
  
  const auth = useContext(AuthContext);

  return (
    <React.Fragment>
    { auth.isLoggedIn && 
    <div className="admin-panel-page">
      <SideBar />
      <div className="admin-panel-content-container">
        <NavBarAdmin />
        <div className="admin-panel-main-content">
          <Route path="/admin-panel" exact>
            <Home />
          </Route>
          <Route path="/admin-panel/products/list-products" exact>
             <ProductList />
          </Route>
          <Route path="/admin-panel/products/add-product" exact>
            <AddProduct />
          </Route>
          <Route path="/admin-panel/sales/orders" exact>
            <Orders />
          </Route>
          <Route path="/admin-panel/sales/transactions" exact>
            <Transactions />
          </Route>
          <Route path="/admin-panel/coupons/list-coupons" exact>
            <ListCoupons />
          </Route>
          <Route path="/admin-panel/coupons/create-coupons" exact>
            <AddCoupon />
          </Route>
          <Route path="/admin-panel/pages/list-page" exact>
            <PageList />
          </Route>
          <Route path="/admin-panel/pages/create-page" exact>
            <AddPage />
          </Route>
          <Route path="/admin-panel/media" exact>
            <Media />
          </Route>
          <Route path="/admin-panel/menus/list-menu" exact>
            <MenuList />
          </Route>
          <Route path="/admin-panel/menus/create-menu" exact>
            <AddMenu />
          </Route>
          <Route path="/admin-panel/users/user-list" exact>
            <UserList />
          </Route>
          <Route path="/admin-panel/users/create-user" exact>
            <AddUser />
          </Route>
          <Route path="/admin-panel/vendors/vendor-list" exact>
            <VendorList />
          </Route>
          <Route path="/admin-panel/vendors/create-vendor" exact>
            <AddVendor />
          </Route>
          <Route path="/admin-panel/reports" exact>
            <Reports />
          </Route>
          <Route path="/admin-panel/settings/profile" exact>
            <ProfilePage />
          </Route>
          <Route path="/admin-panel/invoice" exact>
            <Invoice />
          </Route>
          <Route path="/admin-panel/manufacturer/list-manufacturer" exact>
            <ManufacturerList />
          </Route>
          <Route path="/admin-panel/manufacturer/add-manufacturer" exact>
            <CreateManufacturer />
          </Route>
          <Route path="/admin-panel/orders/place-order" exact>
            <PlaceOrder />
          </Route>
          <Route path="/admin-panel/orders/list-orders" exact>
            <ListOrders />
          </Route>
          <Route path="/admin-panel/stocks" exact>
            <Stocks />
          </Route>
          <Route path="/admin-panel/billing" exact>
            <Bill />
          </Route>
        </div>
      </div>
    </div> }
    {!auth.isLoggedIn && <center>
      <h1>Not Found</h1>
    </center>}
    </React.Fragment>
  );
}
