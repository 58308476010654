import React from "react";
import Input from "../../../../Form/components/Input";
import PanelButton from "../../../Buttons/PanelButton";

import "./AddMenu.css";

export default function AddMenu(props) {
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">CREATE MENU</h2>

      <div className="multi-page-form">
        <div className="multi-page-main-form-container">
          <form>
            <Input label="Menu Name " />
            <span className="extra-label">Status :</span>
            <input type="checkbox" />
            <span>Enable the coupon</span>
            <br />
            <br />
            <PanelButton className="add-page-btn">SAVE</PanelButton>
          </form>
        </div>
      </div>
    </div>
  );
}
