import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';

import AutoCarousel from "../components/Carousel/Carousel";
import Testimonials from "../components/Testimonials/Testimonials";
import InfoWithImage from "../components/Info Section/InfoWithImage";
import aboutUsImage from "../Images/about.jpg";


import "./Home.css";
import Axios from "axios";


export default function Home(props) {

  const [products,setProducts] = useState([]);

    useEffect(()=>{
      
        const sendReq = async ()=>{
            const response = await Axios.get(process.env.REACT_APP_BACKEND_URL+"/api/products/get-products");
            setProducts(response.data);
          }
     
          sendReq();


    },[])

  return (
    <div className="container">
      <MetaTags>
        <meta name = "description" content = "Synapes life science is a pharmaceutical company working for the betterment of the society and people,inorder to improve the health and wellbeing of the people. Our goal is to improve global health."/>
        <meta name = "keywords" content = "healthcare, Synapes life sciences, global health, healthy life, health and wellness business, private clinic, private clinic near me"/>
      </MetaTags>
      <AutoCarousel />
      <InfoWithImage
        heading="About Synapes Life Sciences"
        left="true"
        image={aboutUsImage}
        content="We seek to develop medicines and products that can produce positive real-world outcomes for patients and healthcare providers. The benefits can range from improving the cost effectiveness of high-quality care to prolonging lives. We are developing services and technologies to augment the benefits of our core products, often in collaboration with healthcare providers and technology companies. We are committed to creating a culture of integrity and we believe that, as a global leader in healthcare, we have a responsibility to serve as a role model in how we conduct our business. We focus our corporate responsibility work on two areas that underscore our mission, expanding access to healthcare and doing business responsibly. We care for our associates, contribute to our local communities, and strive to operate with high integrity and transparency."
      />
      <div className = "website-stats-section">
          <p className = "stats-main-heading">We have 25 years of experience</p>
          <p className = "stats-sub-heading">We have rich experience in critical care segment, neurology segment and nephology segment, We are covering all the major hospitals and customers.</p>
          <div className = "website-stats-section-cards-container">
              <div className = "website-stats-section-cards">
                  <div className = "website-stats-content-container">
                       <p className = "website-stats-number">50+</p>
                       <p className = "website-stats-title">Medicines</p>
                  </div>
              </div>
              <div className = "website-stats-section-cards">
                  <div className = "website-stats-content-container">
                       <p className = "website-stats-number">100+</p>
                       <p className = "website-stats-title">Expert Technicians</p>
                  </div>
              </div>
              <div className = "website-stats-section-cards">
                  <div className = "website-stats-content-container">
                       <p className = "website-stats-number">20000+</p>
                       <p className = "website-stats-title">Happy Customers</p>
                  </div>
              </div>
              <div className = "website-stats-section-cards">
                  <div className = "website-stats-content-container">
                       <p className = "website-stats-number">150+</p>
                       <p className = "website-stats-title">Field Expertise</p>
                  </div>
              </div>
          </div>
      </div>
      <div className = "products-slider-list">
         <div className = "product-list-background-image"></div>
         <div className = "home-page-products-images-container">
          <h1>Our Products</h1>
           <div className = "home-page-products-images-container-inner">
            {products.map((product,index)=>{
               return (<div key = {index} className = "home-page-product-image-container">

                  <img src = {process.env.REACT_APP_BACKEND_URL+"/"+product.Link} className = "home-page-product-image" alt = {product.Name}/>

               </div>);
            })}
         </div>
         </div>
      </div>
      <Testimonials />
    </div>
  );
}
