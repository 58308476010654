import Axios from "axios";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { tableIcons } from "../../../TableIcons";

import "./VendorList.css";

export default function VendorList(props) {


  const [distributors,setDistributors] = useState([]);
  
  useEffect(()=>{
   
  const sendReq = async () => {
     const response = await Axios.get(
         process.env.REACT_APP_BACKEND_URL + "/api/distributors/get-distributors"
        );
        setDistributors(response.data);
      };
    
      sendReq();


  
  },[])


  return (
    <div className="page-container">
      <h2 className="dashboard-heading">DISTRIBUTORS LIST</h2>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Name", field: "Name" },
          { title: "Email", field: "Email" },
          { title: "Store Name", field: "StoreName" },
          { title: "Phone Number", field: "Mobile" },
          { title: "Employee Area", field: "EmployeeArea" },
          { title: "Payment Left, ₹", field: "PaymentLeft" },
          { title: "Revenue, ₹", field: "Revenue" },
        ]}
        data={distributors}
        actions={[
          {
            icon: "delete",
            iconProps: { style: { color: "#E4566E" } },
            tooltip: "Delete",
            onClick: (event, rowData) => {
              //
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Distributors Details"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
