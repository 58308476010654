import React, { useContext } from "react";
import profileImage from "../Images/profileSample.jpg";
import { Link, useHistory } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/HomeOutlined";
import LocalMallIcon from "@material-ui/icons/LocalMallOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoneyOutlined";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import CartIcon from "@material-ui/icons/ShoppingCartOutlined";
import PostAddIcon from '@material-ui/icons/PostAdd';

import "./SideBar.css";
import { AuthContext } from "../../../context/AuthContext";
import { ShowChartOutlined } from "@material-ui/icons";

export default function SideBar(props) {
  function dropdownClickHandler(event) {
    const id = event.target.id;

    document
      .getElementById("dropdown-" + id)
      .classList.toggle("visible-sidebar-link");
  }

  const auth = useContext(AuthContext);

  const history = useHistory();
  return (
    <div className="admin-panel-sidebar">
      <div
        className="sidebar-top-logo"
        onClick={() => {
          history.push("/");
        }}
      >
        SYNAPES
      </div>
      <div className="admin-panel-sidebar-inner">
        <div className="profile-details-container">
          <img
            src={profileImage}
            alt="profile"
            className="side-panel-profile-image"
          />
          <p className="profile-name">{auth.userName}</p>
          <p className="profile-designation">{auth.userRole}</p>
        </div>
        <br />

        <Link className="link-container" to="/admin-panel">
          <div className="sidebar-link">
            <div className="sidebar-link-header-div">
              <DashboardIcon className="sidebar-icons" />
              <p className="sidebar-links-heading">Dashboard</p>
            </div>
          </div>
        </Link>

        <div className="sidebar-link">
          <div
            className="hoverable-side-link"
            id="coupons"
            onClick={dropdownClickHandler}
          ></div>
          <div className="sidebar-link-header-div dropdown-header-link">
            <LocalMallIcon className="sidebar-icons" />
            <p className="sidebar-links-heading">Products</p>
          </div>
        </div>
        <ul className="sidebar-links-first-sub-dropdown" id="dropdown-coupons">
          <Link
            className="link-container"
            to="/admin-panel/products/list-products"
          >
            <li className="dropdown-header-link">List Products</li>
          </Link>
          <Link
            className="link-container"
            to="/admin-panel/products/add-product"
          >
            <li className="dropdown-header-link">Add Product</li>
          </Link>
        </ul>
        {auth.userRole === "Admin" && 
          <React.Fragment>
          
        <div className="sidebar-link">
          <div
            className="hoverable-side-link"
            id="sales"
            onClick={dropdownClickHandler}
          ></div>
          <div className="sidebar-link-header-div dropdown-header-link">
            <AttachMoneyIcon className="sidebar-icons" />
            <p className="sidebar-links-heading">Sales</p>
          </div>
        </div>
        <ul className="sidebar-links-first-sub-dropdown" id="dropdown-sales">
          <Link className="link-container" to="/admin-panel/sales/orders">
            <li className="dropdown-header-link">Orders</li>
          </Link>
          <Link className="link-container" to="/admin-panel/sales/transactions">
            <li className="dropdown-header-link">Transactions</li>
          </Link>
        </ul>
        </React.Fragment>
        }
        <Link className="link-container" to="/admin-panel/media">
          <div className="sidebar-link">
            <div className="sidebar-link-header-div">
              <CameraAltOutlinedIcon className="sidebar-icons" />
              <p className="sidebar-links-heading">Media</p>
            </div>
          </div>
        </Link>

        {auth.userRole === "Admin" &&
        <React.Fragment>
        <div className="sidebar-link">
          <div
            className="hoverable-side-link"
            id="users"
            onClick={dropdownClickHandler}
          ></div>
          <div className="sidebar-link-header-div dropdown-header-link">
            <PersonAddOutlinedIcon className="sidebar-icons" />
            <p className="sidebar-links-heading">Employees</p>
          </div>
        </div>

        <ul className="sidebar-links-first-sub-dropdown" id="dropdown-users">
          <Link className="link-container" to="/admin-panel/users/user-list">
            <li className="dropdown-header-link">Employees List</li>
          </Link>
          <Link className="link-container" to="/admin-panel/users/create-user">
            <li className="dropdown-header-link">Create Employee</li>
          </Link>
        </ul>
        </React.Fragment>
        }
        <div className="sidebar-link">
          <div
            className="hoverable-side-link"
            id="vendors"
            onClick={dropdownClickHandler}
          ></div>
          <div className="sidebar-link-header-div dropdown-header-link">
            <PeopleAltOutlinedIcon className="sidebar-icons" />
            <p className="sidebar-links-heading">Distributors</p>
          </div>
        </div>

        <ul className="sidebar-links-first-sub-dropdown" id="dropdown-vendors">
          <Link
            className="link-container"
            to="/admin-panel/vendors/vendor-list"
          >
            <li className="dropdown-header-link">Distributors List</li>
          </Link>
          <Link
            className="link-container"
            to="/admin-panel/vendors/create-vendor"
          >
            <li className="dropdown-header-link">Create Distributor</li>
          </Link>
        </ul>
        <Link className="link-container" to="/admin-panel/billing">
          <div className="sidebar-link">
            <div className="sidebar-link-header-div">
              <PostAddIcon className="sidebar-icons" />
              <p className="sidebar-links-heading">Billing</p>
            </div>
          </div>
        </Link>
        <div className="sidebar-link">
          <div
            className="hoverable-side-link"
            id="manufacturer"
            onClick={dropdownClickHandler}
          ></div>
          <div className="sidebar-link-header-div dropdown-header-link">
            <AccountBoxOutlinedIcon className="sidebar-icons" />
            <p className="sidebar-links-heading">Manufacturers</p>
          </div>
        </div>
        <ul className="sidebar-links-first-sub-dropdown" id="dropdown-manufacturer">
          <Link className="link-container" to="/admin-panel/manufacturer/list-manufacturer">
            <li className="dropdown-header-link">List Manufacturers</li>
          </Link>
          <Link className="link-container" to="/admin-panel/manufacturer/add-manufacturer">
            <li className="dropdown-header-link">Create Manufacturer</li>
          </Link>
       </ul>

        <div className="sidebar-link">
          <div
            className="hoverable-side-link"
            id="orders"
            onClick={dropdownClickHandler}
          ></div>
          <div className="sidebar-link-header-div dropdown-header-link">
            <CartIcon className="sidebar-icons" />
            <p className="sidebar-links-heading">Orders</p>
          </div>
        </div>
        <ul className="sidebar-links-first-sub-dropdown" id="dropdown-orders">
          <Link className="link-container" to="/admin-panel/orders/list-orders">
            <li className="dropdown-header-link">List Orders</li>
          </Link>
          <Link className="link-container" to="/admin-panel/orders/place-order">
            <li className="dropdown-header-link">Place Order</li>
          </Link>
        </ul>

        <Link className="link-container" to="/admin-panel/stocks">
          <div className="sidebar-link">
            <div className="sidebar-link-header-div">
              <ShowChartOutlined className="sidebar-icons" />
              <p className="sidebar-links-heading">Stock</p>
            </div>
          </div>
        </Link>

        
        {auth.userRole === "Admin" && 
        <Link className="link-container" to="/admin-panel/reports">
          <div className="sidebar-link">
            <div className="sidebar-link-header-div">
              <PollOutlinedIcon className="sidebar-icons" />
              <p className="sidebar-links-heading">Reports</p>
            </div>
          </div>
        </Link>
        }
        <div className="sidebar-link">
          <div
            className="hoverable-side-link"
            id="settings"
            onClick={dropdownClickHandler}
          ></div>
          <div className="sidebar-link-header-div dropdown-header-link">
            <SettingsOutlinedIcon className="sidebar-icons" />
            <p className="sidebar-links-heading">Settings</p>
          </div>
        </div>
        <ul className="sidebar-links-first-sub-dropdown" id="dropdown-settings">
          <Link className="link-container" to="/admin-panel/settings/profile">
            <li className="dropdown-header-link">Profile</li>
          </Link>
        </ul>

        <Link className="link-container" to="/admin-panel/invoice">
          <div className="sidebar-link">
            <div className="sidebar-link-header-div">
              <ReceiptOutlinedIcon className="sidebar-icons" />
              <p className="sidebar-links-heading">Invoice</p>
            </div>
          </div>
        </Link>

        <Link className="link-container" to="/">
          <div className="sidebar-link">
            <div className="sidebar-link-header-div">
              <ExitToAppOutlinedIcon className="sidebar-icons exit-icon" />
              <p className="sidebar-links-heading">Back to Website</p>
            </div>
          </div>
        </Link>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
