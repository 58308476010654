import MaterialTable from "material-table";
import React from "react";
import { tableIcons } from "../../TableIcons";

import Dropzone from "../../../Form/components/Dropzone";

import "./Media.css";

export default function Media(props) {
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">MEDIA</h2>
      <div className="media-dropzone-container">
        <Dropzone />
      </div>
      <br />
      <br />

      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Id", field: "id" },
          {
            field: "image",
            title: "Image",
            render: (rowData) => (
              <img src={rowData.image} style={{ width: 50 }} alt="product" />
            ),
          },
          { title: "File Name", field: "file_name" },
          { title: "Url", field: "url" },
        ]}
        data={[
          {
            id: 1278,
            image:
              "https://react.pixelstrap.com/multikart-admin/static/media/21.a393cf8e.jpg",
            file_name: "Apple_6s.jpeg",
            url: "http://www.assets/images/electronics/product/13.jpg",
          },
          {
            id: 1278,
            image:
              "https://react.pixelstrap.com/multikart-admin/static/media/21.a393cf8e.jpg",
            file_name: "Apple_6s.jpeg",
            url: "http://www.assets/images/electronics/product/13.jpg",
          },
          {
            id: 1278,
            image:
              "https://react.pixelstrap.com/multikart-admin/static/media/21.a393cf8e.jpg",
            file_name: "Apple_6s.jpeg",
            url: "http://www.assets/images/electronics/product/13.jpg",
          },
          {
            id: 1278,
            image:
              "https://react.pixelstrap.com/multikart-admin/static/media/21.a393cf8e.jpg",
            file_name: "Apple_6s.jpeg",
            url: "http://www.assets/images/electronics/product/13.jpg",
          },
          {
            id: 1278,
            image:
              "https://react.pixelstrap.com/multikart-admin/static/media/21.a393cf8e.jpg",
            file_name: "Apple_6s.jpeg",
            url: "http://www.assets/images/electronics/product/13.jpg",
          },
          {
            id: 1278,
            image:
              "https://react.pixelstrap.com/multikart-admin/static/media/21.a393cf8e.jpg",
            file_name: "Apple_6s.jpeg",
            url: "http://www.assets/images/electronics/product/13.jpg",
          },
          {
            id: 1278,
            image:
              "https://react.pixelstrap.com/multikart-admin/static/media/21.a393cf8e.jpg",
            file_name: "Apple_6s.jpeg",
            url: "http://www.assets/images/electronics/product/13.jpg",
          },
        ]}
        actions={[
          {
            icon: "delete",
            iconProps: { style: { color: "#E4566E" } },
            tooltip: "Delete",
            onClick: (event, rowData) => {
              // Do save operation
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Product Lists"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
