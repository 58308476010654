import React from "react";

import "./InfoWithImage.css";

export default function InfoWithImage(props){
    return (
     
        <div className = "info-with-image-section">
             {props.left &&
             
              <div className = {"info-image-container-left " + props.imageClass}>
                   <img className = "info-image" src = {props.image} alt = "info"/>
              </div>
             }
             <div className = "info-with-image-content-section">
                  <h1 className = "info-section-heading">{props.heading}</h1>
                  <p className= "info-section-content">{props.content}</p>
                  {props.children}
             </div>

             {props.right &&
             
             <div className = { "info-image-container-right "+props.imageClass }>
                   <img className = "info-image" src = {props.image} alt = "info"/>
             </div>
            }
        </div>

    );
}