import MaterialTable from "material-table";
import React from "react";
import { tableIcons } from "../../../TableIcons";

import "./Transactions.css";

export default function Transactions(props) {
  return (
    <div className="page-container">
      <h2 className="dashboard-heading">TRANSACTIONS</h2>
      <MaterialTable
        icons={tableIcons}
        columns={[
          { title: "Order Id", field: "id" },
          { title: "Transaction Id", field: "t_id" },
          { title: "Date", field: "date" },
          { title: "Payment", field: "payment" },
          { title: "Status", field: "status" },
          { title: "Amount", field: "amount" },
        ]}
        data={[
          {
            id: "#5140",
            t_id: "#212145214510",
            date: "Sep 2, 2019",
            payment: "Paypal",
            status: "Pending",
            amount: "₹13000",
          },
          {
            id: "#5140",
            t_id: "#212145214510",
            date: "Sep 2, 2019",
            payment: "Paypal",
            status: "Pending",
            amount: "₹13000",
          },
          {
            id: "#5140",
            t_id: "#212145214510",
            date: "Sep 2, 2019",
            payment: "Paypal",
            status: "Pending",
            amount: "₹13000",
          },
          {
            id: "#5140",
            t_id: "#212145214510",
            date: "Sep 2, 2019",
            payment: "Paypal",
            status: "Pending",
            amount: "₹13000",
          },
          {
            id: "#5140",
            t_id: "#212145214510",
            date: "Sep 2, 2019",
            payment: "Paypal",
            status: "Pending",
            amount: "₹13000",
          },
          {
            id: "#5140",
            t_id: "#212145214510",
            date: "Sep 2, 2019",
            payment: "Paypal",
            status: "Pending",
            amount: "₹13000",
          },
        ]}
        actions={[
          {
            icon: "delete",
            iconProps: { style: { color: "#E4566E" } },
            tooltip: "Delete",
            onClick: (event, rowData) => {
              // Do save operation
            },
          },
          (rowData) => ({
            icon: "edit",
            iconProps: { style: { color: "#28A757" } },
            tooltip: "Edit",
            onClick: (event, rowData) => {},
          }),
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        title="Transaction Details"
      />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
