import React, {useState } from "react";

import initialImage from "../../Images/InitialImage.svg";
import "./ImageInput.css";

export default function ImageInput(props) {
  function imageContainerClickhandler() {
    document.querySelector(".image-file-input").click();
  }

  const [image,setImage] = useState("");
  
function inputHandler(event){
  setImage(URL.createObjectURL(event.target.files[0]));
}
  return (
    <div
      className={`image-input ${props.className}`}
      onClick={imageContainerClickhandler}
    >
      <img src={image || initialImage} alt="camera" />
      <div className="image-input-overlay">Click here to upload an image</div>
      <input
        name={props.name}
        type="file"
        className="image-file-input"
        onChange={props.onChange}
        accept="image/png, image/jpeg, image/jpg"
        onInput = {inputHandler}
        value={props.value}
      />
    </div>
  );
}
