import React from "react";
import Image1 from "../../Images/testimonial/testimonial1.png";
import Image2 from "../../Images/testimonial/testimonial2.png";
import Image3 from "../../Images/testimonial/testimonial3.png";

import "./Testimonial.css";
import TestimonialCard from "./components/TestimonialCard";

export default function Testimonials() {
  return (
    <div className="testimonial">
      <p className="testimonial-top-heading">TESTIMONIALS</p>
      <p className="testimonial-main-heading">Happy Customers & Clients</p>
      <p className="testimonial-heading-text">
        Our collaboration projects between the public sector, industry and
        academia bring a real impact for the our health and lab firms.
      </p>
      <div className="testimonial-cards-container">
        <TestimonialCard
          content="Thank you, just want to say that everyone that I’ve ever dealt with at Synapes Life Sciences Pvt. Ltd., including you, have provided THE BEST customer service. Probably the best I’ve had from any industry ever."
          personName="Jenifer Hearly"
          personImage={Image1}
        />
        <TestimonialCard
          content="Always a pleasure dealing with Synapes Life Sciences Pvt. Ltd. Your customer service is unbeatable. You should be teaching ALL other companies how to handle their customers so well!"
          personName="Julie Parker"
          personImage={Image2}
        />
        <TestimonialCard
          content="I was most impressed with the professionalism in evidence at Synapes Life Sciences Pvt. Ltd. ….our profound thanks and appreciation, this was a job well done!"
          personName="Maria Flynn"
          personImage={Image3}
        />
      </div>
    </div>
  );
}
