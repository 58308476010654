import React, { useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import profileImage from "../Images/profileSample.jpg";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import SearchIcon from "@material-ui/icons/Search";

import "./NavBarAdmin.css";

export default function NavBarAdmin(props) {
  function menuClickHandler() {
    document
      .querySelector(".admin-panel-sidebar")
      .classList.toggle("hidden-sidebar");
    document
      .querySelector(".admin-panel-sidebar-inner")
      .classList.toggle("hidden-class");
    document
      .querySelector(".sidebar-top-logo")
      .classList.toggle("hidden-class");
    document
      .querySelector(".admin-panel-content-container")
      .classList.toggle("admin-panel-content-container-maxify");
    document
      .querySelector(".nav-bar-admin")
      .classList.toggle("nav-bar-admin-maxify");
  }

  const [fullScreenMode, setFullScreenMode] = useState(false);

  function toggleFullscreen() {
    setFullScreenMode((prev) => !prev);

    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  return (
    <div className="nav-bar-admin">
      <div className="nav-bar-admin-left">
        <MenuIcon
          className="nav-bar-admin-icon"
          onClick={menuClickHandler}
          style={{ marginLeft: "30px" }}
        />
      </div>

      <div className="nav-bar-admin-right">
        <div className="nav-bar-admin-search-bar-div">
          <div className="search-icon-container">
            <SearchIcon className="search-icon" />
          </div>
          <input
            name="search"
            className="navbar-search-input"
            placeholder="Search..."
          />
        </div>

        {!fullScreenMode && (
          <FullscreenIcon
            className="nav-bar-admin-icon"
            onClick={toggleFullscreen}
          />
        )}
        {fullScreenMode && (
          <FullscreenExitIcon
            className="nav-bar-admin-icon"
            onClick={toggleFullscreen}
          />
        )}
        <NotificationsNoneIcon className="nav-bar-admin-icon" />

        <ChatBubbleOutlineIcon className="nav-bar-admin-icon" />

        <img
          src={profileImage}
          alt="profile"
          className="admin-panel-profile-image"
        />
      </div>
    </div>
  );
}
