import React from "react";

import "./ContactUs.css";
import contactImage from "../Images/contact.jpg";

export default function ContactUs(props){
    return (
        <div className = "container">
            <div className = "contact-us-cards-container">
                  <div className = "contact-us-card">
                     <h3 className = "contact-us-card-heading">Synapes Life Sciences Pvt. Ltd.</h3>
                     <span className = "contact-us-label">Street : </span><span className = "contact-us-value">310 Aggarwal Chamber, Plot No.4</span><br /><br />
                     <span className = "contact-us-label">City : </span><span className = "contact-us-value">Sector 12, Dwarka, New Delhi-110078</span><br /><br />
                     <span className = "contact-us-label">Country : </span><span className = "contact-us-value">India</span><br /><br />
                  </div>

                  <div className = "contact-us-card">
                     <h3 className = "contact-us-card-heading">24/7 Quick Contact</h3>
                     <span className = "contact-us-label">Phone : </span><span className = "contact-us-value">011-49124675</span><br /><br />
                     <span className = "contact-us-label">Email : </span><span className = "contact-us-value">synapeslifesc@gmail.com</span><br /><br />
                     <span className = "contact-us-label">Website : </span><span className = "contact-us-value">www.synapeslifesc.in</span><br /><br />
                  </div>

                  <div className = "contact-us-card">
                     <h3 className = "contact-us-card-heading">Working Hours</h3>
                     <span className = "contact-us-label">Mon - Fri : </span><span className = "contact-us-value">09.00am to 18.00pm</span><br /><br />
                     <span className = "contact-us-label">Saturday : </span><span className = "contact-us-value">10.00am to 16.00pm</span><br /><br />
                     <span className = "contact-us-label">Sunday : </span><span className = "contact-us-value">Closed</span><br /><br />
                  </div>
            </div>

            <div className = "contact-us-form-container">
                <div className= "contact-us-image-container">
                   <img src = {contactImage} alt = "contact" />
                </div>
                <div className = "contact-us-form-inner-container">
                    <form className = "contact-us-form">
                        <p className = "contact-form-heading">Send Your Message To Us</p>
                        <div className = "dual-input-container-contact-form">
                          <input className = "contact-form-input" placeholder = "Your Name*" required/>
                          <input className = "contact-form-input" placeholder = "Your Phone*" required/>
                        </div>
                        <div className = "dual-input-container-contact-form">
                          <input className = "contact-form-input" placeholder = "Your Email*" required/>
                          <input className = "contact-form-input" placeholder = "Subject"/>
                        </div>
                        <div className = "dual-input-container-contact-form">
                          <textarea rows = "6" className = "contact-form-textarea" placeholder = "Message*" required/>
                        </div>
                        <button className = "contact-form-button">Send Message</button>
                    </form>
                </div>
            </div>

            <div className = "map-container">
            <iframe title = "google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3503.119031814774!2d77.04697831455877!3d28.596205692476026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1ad098951423%3A0x3aea4bab3b2fa138!2sSynapes%20Life%20Sciences!5e0!3m2!1sen!2sin!4v1607685555239!5m2!1sen!2sin" className = "map" frameborder="0" style={{border:"0"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
        </div>
    );
}