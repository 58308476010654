import React from "react";
import { Route, Switch } from "react-router-dom";
import AdminPanelRoutes from "../components/Admin Panel/Routes/AdminPanelRoutes";
import Footer from "../components/Footer/Footer";
import MainNavigation from "../components/Navigation/MainNavigation";
import TopNav from "../components/Navigation/TopNav";
import AboutUs from "../Pages/AboutUs";
import Careers from "../Pages/Careers";
import ContactUs from "../Pages/ContactUs";
import Home from "../Pages/Home";
import Products from "../Pages/Products";
import ResponsibilityPage from "../Pages/ResponsibilityPage";

export default function Routes(props) {
  return (
    <Switch>
      <Route path="/" exact>
        <TopNav />
        <MainNavigation />
        <Home />
        <Footer />
      </Route>
      <Route path="/about" exact>
        <TopNav />
        <MainNavigation />
        <AboutUs />
        <Footer />
      </Route>
      <Route path="/careers" exact>
        <TopNav />
        <MainNavigation />
        <Careers />
        <Footer />
      </Route>
      <Route path="/products" exact>
        <TopNav />
        <MainNavigation />
        <Products />
        <Footer />
      </Route>
      <Route path="/responsibility-and-health" exact>
        <TopNav />
        <MainNavigation />
        <ResponsibilityPage />
        <Footer />
      </Route>
      <Route path="/contact" exact>
        <TopNav />
        <MainNavigation />
        <ContactUs />
        <Footer />
      </Route>
         <AdminPanelRoutes />
    </Switch>
  );
}
