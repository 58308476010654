import {useState,useCallback,useEffect} from "react";

export const useAuth = () =>{
   
  const [userId, setUserID] = useState();
  const [userEmail,setUserEmail] = useState();
  const [userName,setUserName] = useState();
  const [userRole,setUserRole] = useState();
  const login = useCallback((uid,useremail,firstname,role)=>{
    setUserID(uid);
    setUserEmail(useremail);
    setUserName(firstname);
    setUserRole(role);
    localStorage.setItem('userData',JSON.stringify({userId  : uid,email:useremail,userName:firstname,userRole:role}))
  },[]);

  const logout = useCallback(()=>{
    setUserID(null);
    setUserEmail(null);
    setUserName(null);
    setUserRole(null);
    localStorage.removeItem('userData');
  },[]);


  useEffect(()=>{
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if(storedData){
       login(storedData.userId,storedData.email,storedData.userName,storedData.userRole);
    }
  },[login])


  return {login, logout,userId,userEmail,userName,userRole};

}