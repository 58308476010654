import React, { useState } from "react";
import Select from "../../../../Form/components/Select";
import Input from "../../../../Form/components/Input";
import PanelButton from "../../../Buttons/PanelButton";

import "./AddCoupon.css";

export default function AddPage(props) {
  const [activeFormPage, setActiveFormPage] = useState(0);

  function pageClickHandler(event) {
    const id = event.target.id;
    const activePage = parseInt(id.substring(id.length - 1));

    setActiveFormPage(activePage);
  }

  return (
    <div className="page-container">
      <h2 className="dashboard-heading">CREATE COUPON</h2>

      <div className="multi-page-form">
        <div className="multipage-controller">
          <ul className="multipage-controller-list">
            <li
              className={
                activeFormPage === 0 && "multipage-controller-list-active"
              }
              id="multi-page-form-0"
              onClick={pageClickHandler}
            >
              General
            </li>
            <li
              className={
                activeFormPage === 1 && "multipage-controller-list-active"
              }
              id="multi-page-form-1"
              onClick={pageClickHandler}
            >
              Restriction
            </li>
            <li
              className={
                activeFormPage === 2 && "multipage-controller-list-active"
              }
              id="multi-page-form-2"
              onClick={pageClickHandler}
            >
              Usage
            </li>
          </ul>
        </div>
        <div className="multi-page-main-form-container">
          {activeFormPage === 0 && (
            <form>
              <Input label="Name " />
              <Input label="Code " />
              <Input label="Start Date " type="date" />
              <Input label="End Date " type="date" />
              <span className="extra-label" style={{ marginRight: "20px" }}>
                Free Shipping :
              </span>
              <input type="checkbox" style={{ marginRight: "10px" }} />
              <span>Allow Free Shipping</span>
              <br />
              <Input label="Quantity " type="number" />
              <Select
                name=""
                label="Discount Type "
                options={["Percent", "Fixed"]}
                placeholder="--Select--"
              />
              <span className="extra-label">Status :</span>
              <input type="checkbox" />
              <span>Enable the coupon</span>
              <br />
              <br />
              <PanelButton className="add-page-btn">SAVE</PanelButton>
            </form>
          )}

          {activeFormPage === 1 && (
            <form>
              <Input label="Products " />
              <Select
                name=""
                label="Category "
                options={["Electronics", "Clothes", "Shoes", "Digital"]}
                placeholder="--Select--"
              />
              <Input label="Minimum Spend " type="number" />
              <Input label="Maximum Spend " type="number" />
              <PanelButton className="add-page-btn">SAVE</PanelButton>
            </form>
          )}

          {activeFormPage === 2 && (
            <form>
              <Input label="Per Limit " type="number" />
              <Input label="Per Customer " type="number" />
              <PanelButton className="add-page-btn">SAVE</PanelButton>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
