import React, { useContext, useState } from "react";
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import "./TopNav.css";
import BackDrop from "../BackDrop";
import AuthForm from "../AuthForm/AuthForm";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";

export default function TopNav(props){
   
    const [isPopUpAcive,setIsPopUpActive] = useState(false);

    function popupHandler(){
      setIsPopUpActive(prev=>!prev)
    }

    function logoutClickHandler(events){
        auth.logout();
    }

    const auth = useContext(AuthContext);

   return ( 
    <React.Fragment>  
    {isPopUpAcive && !auth.isLoggedIn && <BackDrop onClick = {popupHandler}/>} 
    {isPopUpAcive && !auth.isLoggedIn && <AuthForm/>}
    <div className = {`top-nav-bar ${props.className}`}>
        <div className = "top-nav-bar-left-side">
            <a href = "tel:011-49124675" className = "no-style-link top-nav-link"><PhoneIcon className = "top-nav-link-icon"/>011-49124675</a>
            <a href = "mailto:synapeslifesc@gmail.com" className = "no-style-link top-nav-link"><MailOutlineIcon className = "top-nav-link-icon"/>synapeslifesc@gmail.com</a>
        </div>
        <div className = "top-nav-bar-right-side">
            <a href = "/" className = "no-style-link top-nav-link" target = "blank"><FacebookIcon className = "top-nav-link-icon"/></a>
            <a href = "https://twitter.com/LifeSynapes/" className = "no-style-link top-nav-link" target = "blank"><TwitterIcon className = "top-nav-link-icon"/></a>
            <a href = "https://www.instagram.com/synapes_lifescience/" className = "no-style-link top-nav-link" target = "blank"><InstagramIcon className = "top-nav-link-icon"/></a>
            <a href = "https://www.linkedin.com/in/synapes-lifesciences-419b34200/" className = "no-style-link top-nav-link" target = "blank"><LinkedInIcon className = "top-nav-link-icon"/></a>
            
        </div>
        {auth.isLoggedIn && <Link className = "no-style-link" to = "/admin-panel"><span className = "login-button-span">My Panel ({auth.userName})</span></Link>}
        {auth.isLoggedIn && <span className = "login-button-span" onClick = {logoutClickHandler}>Log out</span>}
        {!auth.isLoggedIn && <span className = "login-button-span" onClick = {popupHandler}>Login</span>}
    </div>
    </React.Fragment>
   );
}