import React, { useEffect, useState } from "react";
import ProductCard from "../../../Cards/Product Card/ProductCard";

import "./ProductList.css";
import Axios from "axios";

export default function ProductList(props) {

  const [products,setProducts] = useState([]);
  
  useEffect(()=>{
     
     const sendReq = async ()=>{
       const response = await Axios.get(process.env.REACT_APP_BACKEND_URL+"/api/products/get-products");
       setProducts(response.data);
     }

     sendReq();

  },[])
  
  return (
    <div className="page-container centralizing-div">
      <h2 className="dashboard-heading">Product List</h2>
      <div className = "admin-panel-products-container">
       {products.length === 0 && <h1>NO PRODUCTS AVAILABLE</h1>}
       {products.map((product,index)=>{
         return (
         <ProductCard
            key =  {index}
            image={process.env.REACT_APP_BACKEND_URL+"/"+product.Link}
            description={product.Description}
            name = {product.Name}
            Id = {product.Id}
          />);
       })}
      </div>
    </div>
  );
}
